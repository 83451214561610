import { Reducer, configureStore } from '@reduxjs/toolkit';
import { ReduxStore } from '@web/types/redux/state';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, createTransform } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { config } from '../config/env';
import rootReducer from './reducers';

const cleanErrors = (state: any) => {
  const { error: _error, ...newState } = state;
  return newState;
};

const transformPersist = (store: ReduxStore, key: string | number | symbol) => {
  switch (key) {
    case 'user':
      return cleanErrors(store);
    default:
      return store;
  }
};

const CleanupTransform = createTransform(transformPersist, transformPersist, {
  whitelist: ['user'],
});

export const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['entities', 'general', 'user'],
  stateReconciler: autoMergeLevel1,
  transforms: [CleanupTransform],
};

export type ReducerGenerator = (root: Reducer) => Reducer;

interface StoreConfiguration {
  generateReduce?: ReducerGenerator | ReducerGenerator[];
}

const defaultGenerators: ReducerGenerator[] = [];

const initializeStore = (options: StoreConfiguration = {}) => {
  const { generateReduce = [] } = options;
  const reducerGenerators = [...defaultGenerators, ...(Array.isArray(generateReduce) ? generateReduce : [generateReduce])];

  const reducer = reducerGenerators.reduce((reducer, generator) => generator(reducer), rootReducer);

  const store = configureStore({
    reducer,
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: !config.isProduction && {
          /**
           * https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
           * These redux-persist actions should be ignored when the serializableCheck
           * is used to avoid console errors.
           */
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        immutableCheck: !config.isProduction,
      }),
    devTools: !config.isProduction,
  });

  return store;
};

export default initializeStore;
