import { t } from '@lingui/core/macro';
import { CompanyType } from '@sit/client-shared';
import { getDayName } from '@sit/core';
import moment from 'moment';
import { FIFTEEN_DAYS, ONE_DAY } from '../constants/date';
import { timesheetStartsFirstDayOfMonth, timesheetStartsMidOfMonth } from './date';

class TimesheetStartDayValidator {
  constructor(private company: CompanyType) {}

  weekStart = () => this.company.weekStart - 1;
  validate = (value: string) => {
    const timesheetDuration = this.company.timesheetDuration;
    if (timesheetDuration === ONE_DAY) return true;
    if (timesheetDuration === FIFTEEN_DAYS) {
      return timesheetStartsFirstDayOfMonth(value) || timesheetStartsMidOfMonth(value);
    }
    return moment.utc(value).day() === this.weekStart();
  };
  message = () => {
    if (this.company.timesheetDuration === FIFTEEN_DAYS) {
      return t`Invalid start date. Select the 1st or the 16th of the month`;
    }
    return t`Invalid start date. Select a date that is ${getDayName(this.weekStart())}`;
  };
}

const checkPasswordComplexity = (pwd: string) => {
  if (!pwd || pwd.length < 8) return false;
  const hasUpperCase = /[A-Z]/.test(pwd);
  const hasLowerCase = /[a-z]/.test(pwd);
  const hasNumbers = /\d/.test(pwd);
  const hasNonalphas = /\W/.test(pwd);
  return hasLowerCase && hasNonalphas && hasNumbers && hasUpperCase;
};

// biome-ignore lint/complexity/noStaticOnlyClass: intentional, used in a React component
class PhoneNumberValidator {
  // https://github.com/twilio/authy-form-helpers/blob/master/src/form.authy.js
  static validate = (value: string) => {
    return value !== '' && value.match(/^(\d{3})\W*(\d{3})\W*(\d{0,5})$/);
  };
  static message = () => {
    return t`Invalid phone number`;
  };
}

export { checkPasswordComplexity, PhoneNumberValidator, TimesheetStartDayValidator };
