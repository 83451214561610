import { useLingui } from '@lingui/react/macro';
import { PageHeader, PageContent as SharedPageContent } from '@web/components/Page';
import { Tab } from 'carbon-react/lib/components/tabs';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { loadingChunkRetry, useIsAdmin } from '@sit/client-shared';
import CarbonTabsWithReactRouter from '@web/components/CustomTabs/CarbonTabsWithReactRouter';
import ErrorBoundary from '@web/components/ErrorBoundary';
import DashboardContainer from '@web/containers/DashboardContainer';
import { NotFoundMessage } from '@web/containers/NotFoundPage/NotFoundMessage';
import { ErrorComponent } from '@web/containers/TimeApprovals/Error';
import {
  timePreferenceRoutes,
  timePreferenceRoutesArray,
  TimePreferencesKeys,
  timePreferencesRoute,
} from '@web/containers/TimePreferences/helpers/routes';
import PreviewTab from '@web/containers/TimePreferences/PreviewTab';
import { useTimePreferenceTabs } from '@web/hooks/timePreferences/useTimePreferenceTabs';
import { useHasAccessToUserManagement, useIsInternalTenant } from '@web/redux/hooks';
import { lazy, Suspense, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';

const TimeGathering = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/TimeGathering')));
const Notifications = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/Notifications')));
const Security = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/Security')));
const UserManagement = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/UserManagement')));
const Advanced = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/Advanced/Advanced')));
const DownloadApp = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/DownloadApp')));
const DemoSettings = lazy(() => loadingChunkRetry(() => import('@web/containers/TimePreferences/DemoSettings')));

const DashboardDefaultExperienceStyle = css`
  height: calc(100vh - 30px);
`;

const DashboardEmbeddedExperienceStyle = css`
  max-width: none;
  padding: ${({ theme }) => theme.space[2]};
`;

const CustomDashboard = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? DashboardEmbeddedExperienceStyle : DashboardDefaultExperienceStyle)}
`;

const DefaultExperienceStyle = css`
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]};
`;

const EmbeddedExperienceStyle = css`
  max-width: none;
  padding: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[3]};
  margin: calc(${({ theme }) => theme.space[2]} * -1);
  flex: 1;
`;

const PageContent = styled(SharedPageContent)`
  display: flex;
  flex-direction: column;
  margin: 0;
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? EmbeddedExperienceStyle : DefaultExperienceStyle)}
`;

const DefaultContentExperienceStyle = css`
  height: calc(100vh - ${({ theme }) => theme.spacing * 32}px);
`;

const EmbeddedContentExperienceStyle = css`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
 height: calc(100vh - ${({ theme }) => theme.spacing * 11}px);
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => (theme.name === 'SIT-Embeded' ? EmbeddedContentExperienceStyle : DefaultContentExperienceStyle)}
  flex-direction: row;
  div[data-component='tabs'] {
    width: auto;

    & > div {
      /* Arbitraty size based on previous implemntation */
      min-width: 300px;
    }
  }

  [role="tabpanel"] {
    display: none;
  }
`;

const TabsRouter = styled(CarbonTabsWithReactRouter)`
  height: 100%;
  > div {
    height: unset;
    max-height: unset;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const OutletContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  padding: ${({ theme }) => theme.spacing * 3}px;
  padding-top: ${({ theme }) => theme.spacing}px;
  padding-bottom: ${({ theme }) => theme.spacing * 3}px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
  overflow-y: auto;
  flex: 1;
`;

const useVisiblePreferences = () => {
  const isAdmin = useIsAdmin();
  const showUserManagementTab = useHasAccessToUserManagement();
  const isInternalTenant = useIsInternalTenant();
  const { t } = useLingui();

  return useMemo(() => {
    const makePrefenceDefinition = (key: TimePreferencesKeys) => {
      const url = timePreferenceRoutes[key];
      const baseDefinition = {
        key,
        url,
        path: url.replace(`${timePreferencesRoute}/`, ''),
      };

      switch (key) {
        case 'timeGathering':
          return {
            ...baseDefinition,
            label: t`Time gathering`,
          };
        case 'notifications':
          return {
            ...baseDefinition,
            label: t`Notifications`,
          };
        case 'security':
          return {
            ...baseDefinition,
            label: t`Security`,
          };
        case 'userManagement':
          return {
            ...baseDefinition,
            label: t`User management`,
          };
        case 'advanced':
          return {
            ...baseDefinition,
            label: t`Advanced`,
          };
        case 'downloadApp':
          return {
            ...baseDefinition,
            label: t`Download app`,
          };
        case 'demoPreferences':
          return {
            ...baseDefinition,
            label: t`Demo preferences`,
          };
      }
    };

    const result = timePreferenceRoutesArray
      .filter((key) => {
        const hideUserManagement = key === 'userManagement' && !showUserManagementTab;
        const hideAdvanced = key === 'advanced' && !isAdmin;
        const hideDemoPreferences = key === 'demoPreferences' && !isInternalTenant;

        if (hideUserManagement || hideAdvanced || hideDemoPreferences) {
          return false;
        }
        return true;
      })
      .map((key) => makePrefenceDefinition(key));
    // Ensures that indexing at `[0]` always returns a non-undefined value
    return result as [(typeof result)[number], ...typeof result];
  }, [t, showUserManagementTab, isAdmin, isInternalTenant]);
};

const toRoutePath = (key: TimePreferencesKeys, path: string) => {
  return key === 'userManagement' ? `${path}/*` : path;
};

const TimePreferencesIndexLayout = () => {
  const { t } = useLingui();
  const { selectedTabId, handleTabChange } = useTimePreferenceTabs();

  const preferences = useVisiblePreferences();

  return (
    <CustomDashboard showSecondaryNav>
      <Helmet>
        <title>{t`Time Preferences | Sage Intelligent Time`}</title>
      </Helmet>

      <PageContent>
        <PageHeader data-cy="entries-approvals-title" title={t`Time Preferences`} />
        <Content>
          <TabsRouter onTabChange={handleTabChange} align="left" position="left" selectedTabId={selectedTabId}>
            {preferences.map(({ path, label }) => (
              <Tab key={path} tabId={path} title={label} />
            ))}
          </TabsRouter>
          <OutletContent data-pendo-id="approvals-tab-content">
            <Outlet />
          </OutletContent>
        </Content>
      </PageContent>
    </CustomDashboard>
  );
};

interface SwitchPreferenceTabProps {
  tabKey: TimePreferencesKeys;
}
const SwitchPreferenceTab = ({ tabKey }: SwitchPreferenceTabProps) => {
  switch (tabKey) {
    case 'timeGathering':
      return <TimeGathering />;
    case 'notifications':
      return <Notifications />;
    case 'security':
      return <Security />;
    case 'userManagement':
      return <UserManagement />;
    case 'advanced':
      return <Advanced />;
    case 'downloadApp':
      return <DownloadApp />;
    case 'demoPreferences':
      return <DemoSettings />;
  }
};

const TimePreferencesIndex = () => {
  const preferences = useVisiblePreferences();

  return (
    <Routes>
      <Route element={<TimePreferencesIndexLayout />}>
        <Route index element={<Navigate replace to={`${preferences[0].path}`} />} />
        {preferences.map(({ key, path, label }) => (
          <Route
            key={key}
            path={toRoutePath(key, path)}
            element={
              <ErrorBoundary fallbackUI={(error) => <ErrorComponent error={error} />}>
                <Suspense fallback={<PreviewTab title={label} />}>
                  <SwitchPreferenceTab tabKey={key} />
                </Suspense>
              </ErrorBoundary>
            }
          />
        ))}
      </Route>

      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  );
};

export default TimePreferencesIndex;
