import { Trans, useLingui } from '@lingui/react/macro';
import { PatchApiTimeClocksIdBreaksBreakIdRequestBody, PostApiTimeClocksIdDimensionValueRequestBody, TimeClock } from '@sit/client-shared';
import { SelectEmployee } from '@web/components/Fields/Select/Employee';
import DateTimeInput from '@web/components/Shared/DateTimeInput';
import Textarea from '@web/components/Shared/Textarea/DebouncedTextArea';
import { staffTimeClocksRoute, timeClocksRoute } from '@web/containers/TimeClocks/helpers/routes';
import { stringWithFallback } from '@web/helpers/label';
import { useRouteTab } from '@web/hooks/useRouteTabs';
import Box from 'carbon-react/lib/components/box';
import { Checkbox, CheckboxGroup } from 'carbon-react/lib/components/checkbox';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import { Tab, Tabs } from 'carbon-react/lib/components/tabs';
import Typography from 'carbon-react/lib/components/typography';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';
import ClockField, { OnFieldChangeFn } from '../ActiveClock/ClockField';
import { ClockDimensionsField, timeClockDimensions } from '../ActiveClock/constants';
import TimeClockBreaksTable from '../TimeClockBreaksTable';
import DimensionValueField from './DimensionValueField';
import { useModalContent } from './hooks/useModalContent';
import { getTimeClockDate } from './hooks/useTimeClockDate';

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(30%, 1fr));
  grid-template-rows: 1fr;
  gap: ${({ theme }) => theme.spacing * 2}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

const FieldLabel = styled(Typography).attrs({ variant: 'strong' })`
  display: block;
  height: ${({ theme }) => theme.spacing * 3}px;
  margin-bottom: ${({ theme }) => theme.spacing}px;
`;

const FieldContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing * 3}px;
`;

const FieldValue = styled(Typography).attrs({ mb: 0 })``;

const DescriptionField = styled(Textarea)`
  min-height: ${({ theme }) => theme.spacing * 5 - 2}px !important;
  resize: vertical !important;
  padding: ${({ theme }) => theme.spacing * 1.5 - 2}px ${({ theme }) => theme.spacing * 2}px !important;
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 16px;
`;

const TabContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing * 2}px;
`;

const StaffFieldsRoot = styled.div`
  display: grid;
  grid-template: 
    [info] "employee billable" [info] 
    [staff-time-controls-start] "clock-in clock-out" [staff-time-controls-end]
    / 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing * 5}px;
  gap: ${({ theme }) => theme.spacing * 2}px;
`;

const ClockInContainer = styled.div`
  grid-area: clock-in;
`;

const ClockOutContainer = styled.div`
  grid-area: clock-out;
`;

const EmployeeContainer = styled.div`
  grid-area: employee;
`;

const BillableContainer = styled.div`
  grid-area: billable;
`;

const NullableLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface DescriptionOrNotesPayload {
  type: 'description' | 'notes';
  value: string;
}

interface BillablePayload {
  type: 'billable';
  value: boolean;
}

export type ModalContentFieldPayload = DescriptionOrNotesPayload | BillablePayload;

export type ModalContentOnChangeFn = (payload: ModalContentFieldPayload) => void;

export interface ModalContentProps {
  clock: TimeClock;
  isStaff?: boolean;
  isDraft?: boolean;
  disabledDimensions: string[];
  defaultTab?: 'dimensions' | 'breaks';
  onBreakDelete: (id: string) => void;
  onFieldChange: OnFieldChangeFn;
  dimensionFields?: ClockDimensionsField[];
  onBreakChange?: (id: string, body: PatchApiTimeClocksIdBreaksBreakIdRequestBody) => void;
  onDimensionValueChange: (payload: PostApiTimeClocksIdDimensionValueRequestBody) => void;
}

interface TimeClockReadonlyFieldsProps {
  clock: TimeClock;
  readonly: boolean;
  onFieldChange: OnFieldChangeFn;
  timeClockDate: string;
  totalHours: string;
}

const TimeClockReadonlyFields = ({ clock, readonly, totalHours, timeClockDate, onFieldChange }: TimeClockReadonlyFieldsProps) => {
  const { t } = useLingui();
  return (
    <>
      <FieldContainer>
        <FieldLabel>
          <Trans>Date</Trans>
        </FieldLabel>
        <FieldValue>{timeClockDate}</FieldValue>
      </FieldContainer>
      <FieldContainer>
        <FieldLabel>
          <Trans>Total hours</Trans>
        </FieldLabel>
        <FieldValue>{totalHours}</FieldValue>
      </FieldContainer>
      <FieldContainer>
        <CheckboxGroup legend={t`Billable`}>
          <Checkbox checked={clock.billable} disabled={readonly} onChange={({ target }) => onFieldChange({ billable: target.checked })} />
        </CheckboxGroup>
      </FieldContainer>
    </>
  );
};

interface NullableDateTimeInput {
  value: Date | null;
  onChange: (value: Date | null) => void;
}

const NullableDateTimeInput = ({ value, onChange }: NullableDateTimeInput) => {
  const { t } = useLingui();

  const handleChange = () => {
    onChange(value ? null : new Date());
  };

  const tooltipMessage = value ? t`Delete clock out time` : t`Set clock out time`;
  const iconType = value ? 'minus' : 'plus';

  return (
    <DateTimeInput
      value={value ?? new Date()}
      onChange={onChange}
      disabled={value === null}
      label={
        <NullableLabel>
          <Typography variant="strong">
            <Trans>Clock out</Trans>
          </Typography>

          <IconButton onClick={() => handleChange()}>
            <Icon type={iconType} tooltipMessage={tooltipMessage} />
          </IconButton>
        </NullableLabel>
      }
    />
  );
};

interface TimeClockStaffFieldsProps {
  clock: TimeClock;
  isDraft?: boolean;
  readonly: boolean;
  onFieldChange: OnFieldChangeFn;
}

const TimeClockStaffFields = ({ clock, isDraft, readonly, onFieldChange }: TimeClockStaffFieldsProps) => {
  const { t } = useLingui();

  const { clockIn, clockOut } = getTimeClockDate(clock, onFieldChange);

  return (
    <StaffFieldsRoot>
      <ClockInContainer>
        <DateTimeInput
          value={clockIn}
          onChange={(clockInTime) =>
            onFieldChange({
              clockInTime: clockInTime.toISOString(),
            })
          }
          label={t`Clock in`}
        />
      </ClockInContainer>
      <ClockOutContainer>
        {isDraft && (
          <NullableDateTimeInput
            value={clockOut ?? null}
            onChange={(clockOutTime) =>
              onFieldChange({
                clockOutTime: clockOutTime?.toISOString() ?? null,
              })
            }
          />
        )}
        {!isDraft && clockOut && (
          <DateTimeInput
            value={clockOut}
            onChange={(clockOutTime) =>
              onFieldChange({
                clockOutTime: clockOutTime.toISOString(),
              })
            }
            label={t`Clock out`}
          />
        )}
      </ClockOutContainer>
      <EmployeeContainer>
        {!isDraft ? (
          <Box>
            <Typography variant="strong">
              <Trans>Employee</Trans>
            </Typography>
            <Typography mt={1} truncate>
              {stringWithFallback(clock.employee.name)}
            </Typography>
          </Box>
        ) : (
          <SelectEmployee
            label={t`Employee`}
            required
            value={clock.employee.id}
            onChange={(employee) =>
              onFieldChange({
                employee,
              })
            }
          />
        )}
      </EmployeeContainer>
      <BillableContainer>
        <CheckboxGroup legend={t`Billable`}>
          <Checkbox checked={clock.billable} disabled={readonly} onChange={({ target }) => onFieldChange({ billable: target.checked })} />
        </CheckboxGroup>
      </BillableContainer>
    </StaffFieldsRoot>
  );
};

const ModalContent = ({
  clock,
  isStaff,
  isDraft,
  onBreakDelete,
  onFieldChange,
  onBreakChange,
  dimensionFields = timeClockDimensions,
  onDimensionValueChange,
  disabledDimensions,
}: ModalContentProps) => {
  const { t } = useLingui();
  const { fields, dimensionValueFields, timeClockDate, readonly, totalHours, displayStaffControls } = useModalContent(
    clock,
    dimensionFields,
    disabledDimensions,
    isStaff,
    isDraft,
  );

  const currentRoute = isStaff ? staffTimeClocksRoute : timeClocksRoute;

  const match = useMatch(`${currentRoute}/:timeClockId/*`);

  const { params } = match ?? { params: { timeClockId: undefined } };

  const { tabId, changeTab } = useRouteTab(`${currentRoute}/${clock.id}`, 'dimensions', {
    enabled: !!params.timeClockId && params.timeClockId !== 'new',
    defaultIsIndex: true,
  });

  return (
    <>
      {displayStaffControls && <TimeClockStaffFields clock={clock} onFieldChange={onFieldChange} isDraft={isDraft} readonly={readonly} />}
      <FieldsContainer>
        {!displayStaffControls && (
          <TimeClockReadonlyFields
            clock={clock}
            onFieldChange={onFieldChange}
            readonly={readonly}
            timeClockDate={timeClockDate}
            totalHours={totalHours}
          />
        )}
        {fields
          .filter((t) => !['billable', 'description', 'notes'].includes(t.type))
          .map((field) => (
            <ClockField
              key={field.type}
              {...field}
              dimensionFields={dimensionFields}
              startDate={field.clock.clockInTime}
              onDimensionValueChange={onDimensionValueChange}
              onFieldChange={onFieldChange}
            />
          ))}
      </FieldsContainer>
      <BottomContainer>
        {readonly ? (
          <>
            <Box>
              <Typography variant="strong">
                <Trans>Description</Trans>
              </Typography>
              <Typography truncate>{stringWithFallback(clock.description)}</Typography>
            </Box>
            <Box>
              <Typography variant="strong">
                <Trans>Notes</Trans>
              </Typography>
              <Typography truncate>{stringWithFallback(clock.notes)}</Typography>
            </Box>
          </>
        ) : (
          <>
            <DescriptionField
              label={t`Description`}
              defaultValue={clock.description ?? ''}
              onValueChangeDebounced={(value) => onFieldChange({ description: value })}
              debounceDelay={300}
            />
            <DescriptionField
              label={t`Notes`}
              defaultValue={clock.notes ?? ''}
              onValueChangeDebounced={(value) => onFieldChange({ notes: value })}
              debounceDelay={300}
            />
          </>
        )}
      </BottomContainer>

      <Tabs selectedTabId={tabId} onTabChange={changeTab}>
        <Tab tabId="dimensions" title={t`Dimensions`}>
          <TabContent>
            <FieldsContainer>
              {dimensionValueFields.map((dimensionField) => (
                <DimensionValueField
                  key={dimensionField.objectName}
                  {...dimensionField}
                  onChange={onDimensionValueChange}
                  clock={clock}
                  readonly={readonly}
                />
              ))}
            </FieldsContainer>
          </TabContent>
        </Tab>
        {!isDraft && (
          <Tab tabId="breaks" title={t`Breaks`}>
            <TabContent>
              <TimeClockBreaksTable
                editable={isStaff && !readonly}
                fullWidth
                breaks={clock.breaks}
                onBreakDelete={onBreakDelete}
                onBreakChange={onBreakChange}
              />
            </TabContent>
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default ModalContent;
