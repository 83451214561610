import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { ReduxStore } from '../types/redux/state';
import ConfigureStore, { ReducerGenerator, persistConfig } from './configureStore';

const reduxPersistToggle: ReducerGenerator = (rootReducer) => {
  const startupPathname = window.location.pathname.substring(1);

  const disablePersistOn = ['external-auth'];

  if (disablePersistOn.includes(startupPathname)) {
    return rootReducer;
  }

  return persistReducer<Record<string, object>>(persistConfig, rootReducer);
};

const store = ConfigureStore({
  generateReduce: [reduxPersistToggle],
});
const persistor = persistStore(store);

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReduxStore> = useSelector;

export { persistor, store };

// Expose redux store for Cypress:
// @ts-expect-error -- Cypress global
if (window.Cypress) {
  // @ts-expect-error -- only used in cypress
  window.store = store;
}
