import { OptionProps } from 'carbon-react/lib/components/select/option';
import { MapOptions } from './mapOptionWithExternalId';

interface Normalized<T> {
  byValue: Map<string, T>;
  options: OptionProps[];
}

const normalize = <T>(data: readonly T[], mapFn: (data: T, config: MapOptions) => OptionProps): Normalized<T> => {
  const byValue = new Map<string, T>();
  const options: OptionProps[] = [];

  for (const item of data) {
    const option = mapFn(item, { showExternalId: true });

    if (!option.value) {
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string -- Will review
    const value = option.value.toString();

    byValue.set(value, item);
    options.push(option);
  }

  return {
    byValue,
    options,
  };
};

export const prepareOptions = <T>(data: readonly T[], mapFn: (data: T, config: MapOptions) => OptionProps) => {
  return normalize(data, mapFn);
};
