import { Trans } from '@lingui/react/macro';
import { formatIntacctDate, TimesheetType } from '@sit/client-shared';
import styled from 'styled-components';

const TimesheetItem = styled.div`
  white-space: nowrap;
`;

const toDateString = (date: string, dateFormat: string) => formatIntacctDate(date.split('T')[0]!, dateFormat);

interface TimesheetListItemProps {
  timesheet: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>;
  dateFormat: string;
}

export const TimesheetListItem = ({ timesheet, dateFormat }: TimesheetListItemProps) => {
  const { startDate, endDate, id, description } = timesheet;
  const dates = `${toDateString(startDate, dateFormat)} - ${toDateString(endDate, dateFormat)}`;

  return (
    <TimesheetItem key={id}>
      <Trans>
        Dates: <strong>{dates}</strong> Description: <strong>{description || 'N/A'}</strong>
      </Trans>
    </TimesheetItem>
  );
};
