import { GetApiUserError, GetApiUserVariables, RequestError, useGetUser as useGetApiUser, UserType } from '@sit/client-shared';
import { UseQueryOptions, QueryKey } from '@tanstack/react-query';

const variables = {
  requestConfig: {
    skipToast: (error: RequestError<number>) => error.status === 401 || error.status === 403,
  },
} satisfies GetApiUserVariables;

export function useGetUser(
  options?: Omit<UseQueryOptions<UserType, GetApiUserError, UserType, QueryKey>, 'queryKey' | 'queryFn' | 'initialData'> | undefined,
) {
  // The deprecation warning is meant to signify to other hooks and components to use this hook instead.
  return useGetApiUser(variables, options);
}
