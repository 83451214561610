import { useLingui } from '@lingui/react/macro';
import { formatIntacctDate, useUserDateFormat } from '@sit/client-shared';
import DateInput, { DateChangeEvent } from 'carbon-react/lib/components/date';
import Typography from 'carbon-react/lib/components/typography';
import { format, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import TimeInput from '../TimeInput';

const Root = styled.div`
  max-width: fit-content;
`;

const DateFieldContainer = styled.div`
  & label {
    font-weight: ${({ theme }) => theme.compatibility.fontWeights400};
  }
`;

const FieldRoot = styled.div`
`;

export interface DateTimeInputProps {
  label?: string | React.ReactNode;
  value: Date;
  disabled?: boolean;
  onChange: (value: Date) => void;
}

function DateTimeInput({ onChange, value, label, disabled }: DateTimeInputProps) {
  const { t } = useLingui();
  const dateFormat = useUserDateFormat();

  const handleChangeDate = ({ target }: DateChangeEvent) => {
    const { rawValue } = target.value;

    const valueString = value.toISOString();
    const currentDate = valueString.substring(0, 10);

    if (!rawValue || rawValue === currentDate) {
      return;
    }

    const valueTime = valueString.substring(10);
    const newValue = parseISO(`${rawValue}${valueTime}`);

    onChange(newValue);
  };

  const visibleLabel = typeof label === 'string' ? <Typography variant="strong">{label}</Typography> : label;

  return (
    <Root>
      {visibleLabel}
      <DateFieldContainer>
        <DateInput
          disabled={disabled}
          label={t`Date`}
          maxWidth="100%"
          value={format(value, 'yyyy-MM-dd')}
          onChange={handleChangeDate}
          // @ts-ignore -- this field is not allowed and should be reviewed, but it might be a carbon-react issue
          formattedValue={formatIntacctDate(value, dateFormat)}
        />
      </DateFieldContainer>
      <FieldRoot>
        <TimeInput disabled={disabled} value={value} onChange={onChange} />
      </FieldRoot>
    </Root>
  );
}

export default DateTimeInput;
