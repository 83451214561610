import { GetApiUserError, UserType } from '@sit/client-shared';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useGetUser } from '@web/api/users/use-get-user';

export function useCompanies(
  options?: Omit<UseQueryOptions<UserType, GetApiUserError, UserType, QueryKey>, 'queryKey' | 'queryFn' | 'initialData'>,
) {
  const { data: user } = useGetUser(options);
  return user?.companies;
}
