import { Trans } from '@lingui/react/macro';
import { dismissBrowserWarning, useHideBrowserWarning } from '@web/redux/general-slice';
import { useAppDispatch } from '@web/redux/store';
import Toast from 'carbon-react/lib/components/toast';
import Typography from 'carbon-react/lib/components/typography';
import { useState } from 'react';
import { config } from '../../config/env';

// Check if the Opera browser is Opera Mini or Opera Mobile in regular mode (called High Savings Mode)
const isOperaMiniHigh =
  navigator.userAgent.includes('OPR/') && navigator.userAgent.includes('Mobile') && !navigator.userAgent.includes('Presto/');
// Check if the Opera browser is Opera Mini in Extreme Savings Mode
const isOperaMiniExtreme = navigator.userAgent.includes('Opera Mini/') && navigator.userAgent.includes('Presto/');

const isOperaMini = isOperaMiniHigh || isOperaMiniExtreme;

const isIE: boolean = (() => {
  const ua = navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return msie > -1 || trident > -1;
})();
const isBrowserUnsupported = isOperaMini || isIE;

const UnsupportedBrowser = () => {
  const dispatch = useAppDispatch();
  const hideBrowserWarning = useHideBrowserWarning();
  const [supportedOverride, setSupportedOverride] = useState(false);

  const isSupported = !isBrowserUnsupported || hideBrowserWarning || supportedOverride;

  const onDismiss = () => {
    setSupportedOverride(true);
    dispatch(dismissBrowserWarning());
  };

  return !isSupported ? (
    <Toast variant="warning" id="unsupported-browser-toast" onDismiss={onDismiss} maxWidth="1300">
      <p>
        <Typography>
          <strong>
            <Trans>Browser requirements</Trans>
          </strong>
        </Typography>
      </p>
      <p>
        <Trans>
          Sage Intelligent Time doesn't support the version of this web browser. Update your browser to the most current version.{' '}
          <a href={config.VITE_APP_SUPPORTED_BROWSERS_LINK} target="_blank" rel="noopener noreferrer">
            Click here
          </a>{' '}
          to see a list of supported browsers.
        </Trans>
      </p>
    </Toast>
  ) : null;
};

export default UnsupportedBrowser;
