import styled from 'styled-components';
import { SitTheme } from '../../styles/theme';
import { PillTextType, StatusPillColors, usePillLocales } from './hooks/usePillLocales';

const toColor = (type: StatusPillColors, theme: SitTheme) => {
  switch (type) {
    case 'success':
      return theme.colors.pillSuccess;

    case 'error':
      return theme.colors.pillError;

    case 'warning':
      return theme.colors.pillWarning;

    case 'info':
      return theme.colors.pillInfo;

    case 'default':
      return theme.colors.pillDefault;
  }
};

const Pill = styled.div<{ type?: StatusPillColors }>`
  border-radius: 30px;
  font-weight: 600;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 15px;
  background-color: ${({ type = 'default', theme }) => toColor(type, theme)};
  white-space: nowrap;
`;

export interface StatusPillProps {
  text: PillTextType;
  type?: StatusPillColors;
}

const StatusPill = ({ text, type = 'success' }: StatusPillProps): JSX.Element => {
  const pillText = usePillLocales(text);
  return (
    <Pill type={type} data-testid="timesheet-status-pill">
      {pillText}
    </Pill>
  );
};

export default StatusPill;
