import { Trans, useLingui } from '@lingui/react/macro';
import { useGetApiValidateResetToken, usePostApiResetPassword } from '@sit/client-shared';
import { track } from '@web/analytics/analyticsClient';
import trackEvents from '@web/analytics/trackEvents';
import JoinUsDialog from '@web/components/JoinUsDialog';
import { useFlag } from '@web/hooks/useFlags';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';
import PublicPage from '../../components/Shared/Public/PublicPage';
import NotFoundPage from '../NotFoundPage';
import ResetPasswordForm, { ResetPasswordFormValues } from './ResetPasswordForm';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

function useResetPassword() {
  return usePostApiResetPassword({
    onSuccess: () => {
      track(trackEvents.PASSWORD_RESET);
    },
  });
}

const ResetPassword = () => {
  const { resetToken } = useParams<{ resetToken: string }>();
  const { t } = useLingui();
  const navigate = useNavigate();

  // Assume enabled on initial load before flag is fetched
  const pageDisabled = !(useFlag('ENABLE_PASSWORD_LOGIN') ?? true);

  const { mutate: resetPassword, isSuccess, error: resetError } = useResetPassword();

  const { error: validateError } = useGetApiValidateResetToken(
    {
      pathParams: {
        resetToken: resetToken!,
      },
      requestConfig: {
        skipToast: true,
      },
    },
    {
      enabled: !!resetToken,
      retry: false,
    },
  );

  const onSubmit = ({ password }: ResetPasswordFormValues) => {
    if (!resetToken) return;
    resetPassword({
      pathParams: {
        resetToken,
      },
      body: {
        password,
      },
      requestConfig: {
        skipToast: true,
      },
    });
  };

  if (validateError?.status === 404 || !resetToken || resetError?.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{t`Reset Password | Sage Intelligent Time`}</title>
      </Helmet>
      <PublicPage>
        <JoinUsDialog closeable={!pageDisabled} />
        {isSuccess ? (
          <PublicForm title={t`Success`}>
            <Content>
              <Trans>Password reset successfully, please click the button below to login.</Trans>
              <PublicButton
                data-testid="to-login"
                label={t`Log in`}
                buttonType="primary"
                onClick={() => navigate('/login')}
                disabled={pageDisabled}
              />
            </Content>
          </PublicForm>
        ) : (
          <ResetPasswordForm onSubmit={onSubmit} errorMsg={resetError?.message ?? validateError?.message} />
        )}
      </PublicPage>
    </>
  );
};

export default ResetPassword;
