import { addDays, isDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { JoinUsDialogContent } from './JoinUsDialogContent';
import { config } from '@web/config/env';

function setJoinUsLocalStorage(daysFromNow: number) {
  window.localStorage.setItem('joinUsModal', addDays(new Date(), daysFromNow).toISOString());
}

function getJoinUsLocalStorage() {
  const data = window.localStorage.getItem('joinUsModal');
  if (typeof data === 'string') {
    const date = new Date(data);
    if (isDate(date)) {
      return date;
    }
  }
  return null;
}

interface Props {
  closeable: boolean;
}

export default function JoinUsDialog({ closeable }: Props) {
  const [date, setDate] = useState<Date | null>(getJoinUsLocalStorage());

  useEffect(() => {
    const handleStorageChange = () => {
      setDate(getJoinUsLocalStorage());
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const open = !closeable || (!config.isCypress && (!date || date <= new Date()));

  return (
    <JoinUsDialogContent
      open={open}
      closeable={closeable}
      onOk={() => {
        setJoinUsLocalStorage(21);
        setDate(getJoinUsLocalStorage());
      }}
      onRemindMeLater={() => {
        setJoinUsLocalStorage(7);
        setDate(getJoinUsLocalStorage());
      }}
    />
  );
}
