import {
  AutofillValue,
  Cluster,
  GetApiClientsResponse,
  GetApiDimensionsResponse,
  GetApiItemsResponse,
  GetApiProjectsResponse,
  GetApiTasksResponse,
  GetApiTimesheetsByUuidEntriesResponse,
  GetApiTimesheetsByUuidRowsResponse,
  TimesheetEntry,
  TimesheetEntryWithDimensions,
  TimesheetRow,
  UserType,
} from '@sit/client-shared';
import { isValid } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import { config } from '../../config/env';
import { objectHasNestedProperty } from '../../helpers/objects';
import { AutofillStoreRow, AutofillStoreRowItem } from '../../types/redux/stores/autofill.types';

const entryHasDimensionValue = (entry: Pick<TimesheetEntryWithDimensions, 'dimensions'>, dimensionValue: string) => {
  return !!entry.dimensions?.some((d) => d.dimension_value_id === dimensionValue);
};

const hasAutofill = (
  rowAutofill: AutofillStoreRow | Record<string, AutofillStoreRow> | null,
): rowAutofill is AutofillStoreRow | Record<string, AutofillStoreRow> => !!rowAutofill;

const isEntryAutofill = (
  entryId: string,
  dimensionId: string,
  autofills: AutofillStoreRow | Record<string, AutofillStoreRow>,
): autofills is Record<string, AutofillStoreRow> => objectHasNestedProperty(autofills, [entryId, dimensionId]) || false;

const getHighestOrder = <T extends { order: number }>(a: T, b: T) => (a.order < b.order ? b : a);

const getDimensionAutofillValueId = (
  dimensionId: string | null | undefined,
  entryId: string | null,
  rowAutofill: AutofillStoreRow | Record<string, AutofillStoreRow> | null,
): AutofillStoreRowItem | null => {
  if (!hasAutofill(rowAutofill) || !dimensionId) return null;
  if (entryId && isEntryAutofill(entryId, dimensionId, rowAutofill)) {
    // biome-ignore lint/style/noNonNullAssertion: might be an issue but this code is for removal
    const items = rowAutofill[entryId]![dimensionId]!;
    // eslint-disable-next-line unicorn/no-array-callback-reference
    return items[0] ? items.reduce(getHighestOrder, items[0]) : null;
  } else if (Array.isArray(rowAutofill[dimensionId]) && rowAutofill[dimensionId].length > 0) {
    // eslint-disable-next-line unicorn/no-array-callback-reference
    return rowAutofill[dimensionId][0] ? rowAutofill[dimensionId].reduce(getHighestOrder, rowAutofill[dimensionId][0]) : null;
  }
  return null;
};

const calcDimensionValue = ({ autofillValue, value }: { autofillValue: AutofillStoreRowItem | null; value: string | null }) => {
  if (autofillValue?.valueId) {
    if (autofillValue.allowOverride && value) {
      return value;
    }
    return autofillValue.valueId;
  } else if (value) {
    return value;
  } else {
    return null;
  }
};

const dimXValue = ({
  dimensionAutofill,
  dimensionValue,
  recalculateAutofill,
}: {
  dimensionAutofill: AutofillStoreRowItem | null;
  dimensionValue: string | undefined;
  recalculateAutofill: boolean;
}) => {
  if (!recalculateAutofill) return dimensionValue;
  if (dimensionAutofill?.valueId) {
    if (dimensionAutofill.allowOverride && dimensionValue) {
      return dimensionValue;
    }
    return dimensionAutofill.valueId;
  }
  return dimensionValue || undefined;
};

const generateAutofill = ({
  clientsData,
  projectsData,
  tasksData,
  itemsData,
  pClientId,
  pProjectId,
  pTaskId,
  pItemId,
  rowId,
  setAutofill,
  user,
}: {
  clientsData: GetApiClientsResponse | undefined;
  projectsData: GetApiProjectsResponse | undefined;
  tasksData: GetApiTasksResponse | undefined;
  itemsData: GetApiItemsResponse | undefined;
  pClientId: string | null;
  pProjectId: string | null;
  pTaskId: string | null;
  pItemId: string | null;
  rowId: string;
  setAutofill: (args: { rowId: string; a: AutofillValue; from: string }) => void;
  user: UserType | undefined;
}) => {
  user?.autofillValues?.forEach((a) => {
    setAutofill({ rowId, a, from: 'user' });
  });
  if (pClientId) {
    const clientObj = clientsData?.find((c) => c.id === pClientId);
    clientObj?.autofillValues?.forEach((a) => {
      setAutofill({ rowId, a, from: pClientId });
    });
  }
  if (pProjectId) {
    const projectObj = projectsData?.find((p) => p.id === pProjectId);
    projectObj?.autofillValues?.forEach((a) => {
      setAutofill({ rowId, a, from: pProjectId });
    });
  }
  if (pTaskId) {
    const taskObj = tasksData?.find((t) => t.id === pTaskId);
    taskObj?.autofillValues?.forEach((a) => {
      setAutofill({ rowId, a, from: pTaskId });
    });
  }
  if (pItemId) {
    const itemObj = itemsData?.find((i) => i.id === pItemId);
    itemObj?.autofillValues?.forEach((a) => {
      setAutofill({ rowId, a, from: pItemId });
    });
  }
};

const loadAutofilledDimensionValues = ({
  department,
  location,
  costType,
  employeePosition,
  laborClass,
  laborShift,
  laborUnion,
  dimensions,
  rowAutofill,
}: {
  department: string | null;
  location: string | null;
  costType: string | null;
  employeePosition: string | null;
  laborClass: string | null;
  laborShift: string | null;
  laborUnion: string | null;
  dimensions: GetApiDimensionsResponse | undefined;
  rowAutofill: AutofillStoreRow | Record<string, AutofillStoreRow> | null;
}): string[] => {
  if (!dimensions) return [];
  const dimensionValues = compact([department, location, costType, employeePosition, laborClass, laborShift, laborUnion]);
  const dimensionsToAutofill =
    dimensionValues.length > 0
      ? dimensions.filter((d) => {
          return !d.integrationMeta?.objectName?.match(
            /^(LOCATION|DEPARTMENT|COSTTYPE|EMPLOYEEPOSITION|LABORCLASS|LABORSHIFT|LABORUNION)$/,
          );
        })
      : [...dimensions];
  dimensionsToAutofill.forEach((d) => {
    const dimAutofill = getDimensionAutofillValueId(d.id, null, rowAutofill);
    if (dimAutofill?.valueId) {
      dimensionValues.push(dimAutofill.valueId);
    }
    return null;
  });
  return dimensionValues;
};

const loadAutofilledDimensionValuesToRemove = ({ prevOtherValues, otherValues }: { prevOtherValues: string[]; otherValues: string[] }) => {
  return prevOtherValues.filter((v) => !otherValues.includes(v));
};

const animateRow = (rowId: string | null | undefined) => {
  if (!rowId) return;
  const row: HTMLElement | null = document.querySelector(`[data-row-id="${rowId}"] > .values`);
  if (row) {
    const originalStyle = { ...row.style };
    row.style.transition = 'all 5s ease';
    row.style.boxShadow = '0px 0px 5px #0073c2';
    row.style.backgroundColor = 'rgba(0, 115, 192, 0.13)';
    setTimeout(() => {
      row.style.transition = originalStyle.transition;
      row.style.boxShadow = originalStyle.boxShadow;
      row.style.backgroundColor = originalStyle.backgroundColor;
    }, 5000);
  }
};

interface GetMatches {
  dimensionData: {
    showBillable: boolean;
    showCustom: boolean;
    showProject: boolean;
    showTask: boolean;
    showItem: boolean;
    showTimeType: boolean;
    showDepartmentLocation: boolean;
    showCostType: boolean;
    showEmployeePosition: boolean;
    showLaborClass: boolean;
    showLaborShift: boolean;
    showLaborUnion: boolean;
  };
  client: string | null;
  project: string | null;
  task: string | null;
  item: string | null;
  timeType: string | null;
  department: string | null;
  location: string | null;
  costType: string | null;
  employeePosition: string | null;
  laborClass: string | null;
  laborShift: string | null;
  laborUnion: string | null;
  billable: string | boolean;
  timesheetRows: GetApiTimesheetsByUuidRowsResponse | undefined;
}

export interface TimesheetsRowMatches extends TimesheetRow {
  checks: number;
}

const getMatches = ({
  dimensionData,
  client,
  project,
  task,
  item,
  timeType,
  department,
  location,
  costType,
  employeePosition,
  laborClass,
  laborShift,
  laborUnion,
  billable,
  timesheetRows,
}: GetMatches): TimesheetsRowMatches[] => {
  if (dimensionData.showCustom) {
    if (!client) {
      return [];
    }
  } else if (dimensionData.showProject && !project) return [];
  const matches =
    timesheetRows?.reduce<TimesheetsRowMatches[]>((acc, row) => {
      if (!row.entries?.[0] || row.negativeHours) return acc;
      const rowEntry = row.entries[0];
      const checkBillable = rowEntry.billable === billable;
      const checkDepartment = dimensionData.showDepartmentLocation && !!department && entryHasDimensionValue(rowEntry, department);
      const checkLocation = dimensionData.showDepartmentLocation && !!location && entryHasDimensionValue(rowEntry, location);
      const checkTimeType = dimensionData.showTimeType && !!timeType && rowEntry.timeTypeId === timeType;
      const checkItem = dimensionData.showItem && !!item && rowEntry.confirmedItemId === item;
      const checkTask = dimensionData.showTask && !!task && rowEntry.confirmedTaskId === task;
      const checkProject = dimensionData.showProject && !!project && rowEntry.confirmedProjectId === project;
      const checkClient = dimensionData.showCustom && !!client && rowEntry.confirmedClientId === client;
      const checkCostType = dimensionData.showCostType && !!costType && entryHasDimensionValue(rowEntry, costType);
      const checkEmployeePosition =
        dimensionData.showEmployeePosition && !!employeePosition && entryHasDimensionValue(rowEntry, employeePosition);
      const checkLaborClass = dimensionData.showLaborClass && !!laborClass && entryHasDimensionValue(rowEntry, laborClass);
      const checkLaborShift = dimensionData.showLaborShift && !!laborShift && entryHasDimensionValue(rowEntry, laborShift);
      const checkLaborUnion = dimensionData.showLaborUnion && !!laborUnion && entryHasDimensionValue(rowEntry, laborUnion);
      const arr: boolean[] = [];
      if (dimensionData.showBillable && billable !== '-') {
        if (!checkBillable) return acc;
        arr.push(checkBillable);
      }
      if (dimensionData.showDepartmentLocation) {
        if (!!department && !checkDepartment) return acc;
        if (!!location && !checkLocation) return acc;
        arr.push(checkDepartment, checkLocation);
      }
      if (dimensionData.showCostType) {
        if (!!costType && !checkCostType) return acc;
        arr.push(checkCostType);
      }
      if (dimensionData.showEmployeePosition) {
        if (!!employeePosition && !checkEmployeePosition) return acc;
        arr.push(checkEmployeePosition);
      }
      if (dimensionData.showLaborClass) {
        if (!!laborClass && !checkLaborClass) return acc;
        arr.push(checkLaborClass);
      }
      if (dimensionData.showLaborShift) {
        if (!!laborShift && !checkLaborShift) return acc;
        arr.push(checkLaborShift);
      }
      if (dimensionData.showLaborUnion) {
        if (!!laborUnion && !checkLaborUnion) return acc;
        arr.push(checkLaborUnion);
      }
      if (dimensionData.showTimeType) {
        if (!!timeType && !checkTimeType) return acc;
        arr.push(checkTimeType);
      }
      if (dimensionData.showItem) {
        if (!!item && !checkItem) return acc;
        arr.push(checkItem);
      }
      if (dimensionData.showTask) {
        if (!!task && !checkTask) return acc;
        arr.push(checkTask);
      }
      if (dimensionData.showProject) {
        if (!!project && !checkProject) return acc;
        arr.push(checkProject);
      }
      if (dimensionData.showCustom) {
        if (!!client && !checkClient) return acc;
        arr.push(checkClient);
      }
      const checks = arr.filter((c) => c).length;
      if (checks > config.VITE_APP_ROW_CHECKS_MIN) {
        const rowClone = cloneDeep(row);

        acc.push({ ...rowClone, checks });
      }
      return acc;
    }, []) ?? [];
  return matches;
};

const getSingleMatch = ({
  dimensionData,
  client,
  project,
  task,
  item,
  timeType,
  department,
  location,
  costType,
  employeePosition,
  laborClass,
  laborShift,
  laborUnion,
  billable,
  timesheetRows,
  rowId,
}: {
  dimensionData: {
    showCustom: boolean;
    showProject: boolean;
    showTask: boolean;
    showItem: boolean;
    showTimeType: boolean;
    showDepartmentLocation: boolean;
    showCostType: boolean;
    showEmployeePosition: boolean;
    showLaborClass: boolean;
    showLaborShift: boolean;
    showLaborUnion: boolean;
    showBillable: boolean;
  };
  client: string | null;
  project: string | null;
  task: string | null;
  item: string | null;
  timeType: string | null;
  department: string | null;
  location: string | null;
  costType: string | null;
  employeePosition: string | null;
  laborClass: string | null;
  laborShift: string | null;
  laborUnion: string | null;
  billable: string | boolean;
  rowId?: string;
  timesheetRows: GetApiTimesheetsByUuidRowsResponse | undefined;
}) => {
  if (dimensionData.showCustom && !client) return false;
  if (dimensionData.showProject && !project) return false;
  if (dimensionData.showTask && !task) return false;
  if (dimensionData.showItem && !item) return false;
  if (dimensionData.showTimeType && !timeType) return false;
  if (dimensionData.showDepartmentLocation && (!location || !department)) return false;
  if (dimensionData.showCostType && !costType) return false;
  if (dimensionData.showEmployeePosition && !employeePosition) return false;
  if (dimensionData.showLaborClass && !laborClass) return false;
  if (dimensionData.showLaborShift && !laborShift) return false;
  if (dimensionData.showLaborUnion && !laborUnion) return false;
  return timesheetRows?.find((row) => {
    if (row.id === rowId) return false;
    if (!row.entries?.[0]) return false;
    const rowEntry = row.entries[0];
    const checkBillable = rowEntry.billable === billable;
    const checkDepartment = dimensionData.showDepartmentLocation && !!department && entryHasDimensionValue(rowEntry, department);
    const checkLocation = dimensionData.showDepartmentLocation && !!location && entryHasDimensionValue(rowEntry, location);
    const checkTimeType = dimensionData.showTimeType && !!timeType && rowEntry.timeTypeId === timeType;
    const checkItem = dimensionData.showItem && !!item && rowEntry.confirmedItemId === item;
    const checkTask = dimensionData.showTask && !!task && rowEntry.confirmedTaskId === task;
    const checkProject = dimensionData.showProject && !!project && rowEntry.confirmedProjectId === project;
    const checkClient = dimensionData.showCustom && !!client && rowEntry.confirmedClientId === client;
    const checkCostType = dimensionData.showCostType && !!costType && entryHasDimensionValue(rowEntry, costType);
    const checkEmployeePosition =
      dimensionData.showEmployeePosition && !!employeePosition && entryHasDimensionValue(rowEntry, employeePosition);
    const checkLaborClass = dimensionData.showLaborClass && !!laborClass && entryHasDimensionValue(rowEntry, laborClass);
    const checkLaborShift = dimensionData.showLaborShift && !!laborShift && entryHasDimensionValue(rowEntry, laborShift);
    const checkLaborUnion = dimensionData.showLaborUnion && !!laborUnion && entryHasDimensionValue(rowEntry, laborUnion);
    const arr = [];
    if (dimensionData.showBillable) {
      if (!checkBillable) return false;
      arr.push(checkBillable);
    }
    if (dimensionData.showDepartmentLocation) {
      if (!!department && !checkDepartment) return false;
      if (!!location && !checkLocation) return false;
      arr.push(checkDepartment, checkLocation);
    }
    if (dimensionData.showCostType) {
      if (!!costType && !checkCostType) return false;
      arr.push(checkCostType);
    }
    if (dimensionData.showEmployeePosition) {
      if (!!employeePosition && !checkEmployeePosition) return false;
      arr.push(checkEmployeePosition);
    }
    if (dimensionData.showLaborClass) {
      if (!!laborClass && !checkLaborClass) return false;
      arr.push(checkLaborClass);
    }
    if (dimensionData.showLaborShift) {
      if (!!laborShift && !checkLaborShift) return false;
      arr.push(checkLaborShift);
    }
    if (dimensionData.showLaborUnion) {
      if (!!laborUnion && !checkLaborUnion) return false;
      arr.push(checkLaborUnion);
    }
    if (dimensionData.showTimeType) {
      if (!!timeType && !checkTimeType) return false;
      arr.push(checkTimeType);
    }
    if (dimensionData.showItem) {
      if (!!item && !checkItem) return false;
      arr.push(checkItem);
    }
    if (dimensionData.showTask) {
      if (!!task && !checkTask) return false;
      arr.push(checkTask);
    }
    if (dimensionData.showProject) {
      arr.push(checkProject);
      if (!!project && !checkProject) return false;
    }
    if (dimensionData.showCustom) {
      arr.push(checkClient);
      if (!!client && !checkClient) return false;
    }
    const checks = arr.filter((c) => c).length;
    if (checks === arr.length) {
      return true;
    }
    return false;
  });
};

const getOverlappingClusterId = (cluster: Pick<Cluster, 'nodes'> | undefined) => {
  const overlappingNode = cluster?.nodes?.find((node) => node.overlaps && node.overlaps.length > 0);
  return overlappingNode?.overlaps?.find((overlap) => overlap.isOverlapping)?.overlapping_cluster_id ?? null;
};

const getEntryRowAndDateString = (entry: Pick<TimesheetEntry, 'timesheetRowId' | 'date'>) => {
  const { timesheetRowId, date } = entry;
  if (timesheetRowId && date) {
    return `${timesheetRowId}-${date}`;
  }
  return '';
};

const notReadonly = { isReadonly: false } as const;
const tcReason = { isReadonly: true, reason: 'tc' } as const;
const statusReason = { isReadonly: true, reason: 'status' } as const;
const isEntryReadonly = (
  entry?: Pick<TimesheetEntry, 'status' | 'timeClocks'> | null,
): typeof notReadonly | typeof tcReason | typeof statusReason => {
  if (!entry?.status) return notReadonly;
  if (entry.timeClocks && entry.timeClocks.length > 0) return tcReason;
  if (!/^(DRAFT|DECLINED)$/.test(entry.status)) return statusReason;
  return notReadonly;
};

const getLatestEntrySyncTime = (entries: Pick<TimesheetEntry, 'integrationSyncTime'>[]) => {
  return Math.max(...entries.map((e) => new Date(e.integrationSyncTime ?? 0).getTime()));
};

const getLatestEntryUpdateTime = (entries: Pick<TimesheetEntry, 'updatedAt'>[]) => {
  return Math.max(...entries.map((e) => new Date(e.updatedAt).getTime()));
};

// TODO(@tboulis): Move `lastSyncedTimesheet` into Redux selectors for `memoization`.
const lastSyncedTimesheet = (data: GetApiTimesheetsByUuidEntriesResponse | undefined): boolean => {
  if (!data) return false;
  const values = Object.values(data);

  const lastSyncedAsMs = getLatestEntrySyncTime(values);
  const lastUpdatedAsMs = getLatestEntryUpdateTime(values);
  // Handles the case where max is 0 (no entries)
  if (!lastSyncedAsMs || lastUpdatedAsMs > lastSyncedAsMs) return false;
  const latestEntrySyncTime = new Date(lastSyncedAsMs);
  return isValid(latestEntrySyncTime);
};

export {
  animateRow,
  calcDimensionValue,
  dimXValue,
  generateAutofill,
  getDimensionAutofillValueId,
  getEntryRowAndDateString,
  getMatches,
  getOverlappingClusterId,
  getSingleMatch,
  isEntryReadonly,
  lastSyncedTimesheet,
  loadAutofilledDimensionValues,
  loadAutofilledDimensionValuesToRemove,
};
