import { useLingui } from '@lingui/react/macro';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';

export const HelpButton = () => {
  const { t } = useLingui();
  return (
    <IconButton
      data-pendo-id="help"
      aria-label="icon-button"
      onClick={() => window.open(import.meta.env.VITE_APP_HELP_URL, '_blank', 'noopener,noreferrer')}
    >
      <Icon color="white" type="help" tooltipMessage={t`Help`} />
    </IconButton>
  );
};
