import { Trans, useLingui } from '@lingui/react/macro';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

interface ForgotPasswordSuccessProps {
  returnToLogin: () => void;
  resend: () => void;
}
const ResetPasswordSuccess = ({ returnToLogin, resend }: ForgotPasswordSuccessProps) => {
  const { t } = useLingui();
  return (
    <PublicForm title={t`Check your email`}>
      <Content>
        <p>
          <Trans>You’ll receive an email from Sage Intelligent Time shortly with a link to reset your password.</Trans>
        </p>
        <p>
          <Trans>Can’t find that email? Try checking your spam folder.</Trans>
        </p>
        <p>
          <Trans>If you still can’t login, have us resend the email or contact your Sage Intelligent Time administrator.</Trans>
        </p>
      </Content>
      <Actions>
        <PublicButton data-testid="return" buttonType="primary" label={t`Return to login`} onClick={returnToLogin} />
        <PublicButton buttonType="secondary" label={t`Resend`} onClick={resend} />
      </Actions>
    </PublicForm>
  );
};

export default ResetPasswordSuccess;
