import { Plural, useLingui } from '@lingui/react/macro';
import { TimesheetType } from '@sit/client-shared';
import Confirm from 'carbon-react/lib/components/confirm';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';
import { TimesheetsList } from './TimesheetsList';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
`;

interface DeleteTimesheetsModalProps {
  ids: string[];
  timesheets: Pick<TimesheetType, 'startDate' | 'endDate' | 'id' | 'description'>[];
  onClose: () => void;
  onOk: () => void;
}

export const DeleteTimesheetsModal = ({ ids, onClose, onOk, timesheets }: DeleteTimesheetsModalProps) => {
  const { t } = useLingui();
  const data = timesheets.filter((t) => ids.includes(t.id));

  return (
    <Confirm
      confirmButtonDestructive
      onCancel={() => onClose()}
      onConfirm={() => {
        onOk();
        onClose();
      }}
      cancelLabel={t`Cancel`}
      confirmLabel={t`Delete`}
      open={true}
      title={<Plural value={ids.length} one="Delete timesheet" other="Delete # timesheets" />}
      size="medium"
    >
      <Content>
        <Typography variant="strong" display="block">
          <Plural
            value={ids.length}
            one="The following timesheet will be permanently deleted."
            other="The following timesheets will be permanently deleted."
            _0="You selected zero timesheets."
          />
        </Typography>
        <TimesheetsList timesheets={data} />
      </Content>
    </Confirm>
  );
};
