import { Trans } from '@lingui/react/macro';
import CarbonButton from '@web/components/Shared/Button';
import Loader from 'carbon-react/lib/components/loader';
import styled from 'styled-components';

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 120px;
  gap: ${({ theme }) => theme.space[2]};
`;

const Button = styled(CarbonButton)`
  font-size: 16px;
  margin-right: auto;
`;

interface BackButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onBack?: () => void;
}

const BackButton = ({ loading, disabled, onBack }: BackButtonProps) => {
  return (
    <Root>
      <Button data-testid="back-button" buttonType="tertiary" iconType="arrow_left" disabled={disabled} onClick={onBack} size="small">
        <Trans>Back</Trans>
      </Button>
      {loading && <Loader isActive />}
    </Root>
  );
};

export default BackButton;
