import { ReactNode } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import-x/no-unresolved -- todo, figure out how to satisfy eslint
import SageLogo from '../../../assets/img/Logo.svg?react';

interface PublicRouteContainerProps {
  children: ReactNode;
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.palette.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.div`
  width: 100%;
  max-height: 200px;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageContent = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PublicRouteContainer = ({ children }: PublicRouteContainerProps) => {
  return (
    <Container>
      <PageContent>
        <LogoContainer>
          <SageLogo width="142px" height="auto" />
        </LogoContainer>
        {children}
      </PageContent>
    </Container>
  );
};

export default PublicRouteContainer;
