import { msg } from '@lingui/core/macro';
import { DimensionValueType, useGetDimensions } from '@sit/client-shared';
import { SelectProps, buildDefaultSelect } from './buildSelect';
import { getDimension } from './helpers';

export interface CostTypeSelectProps extends SelectProps<DimensionValueType> {
  timesheetId: string | undefined;
  projectId: string | null | undefined;
  taskId: string | null | undefined;
}

const useQuery = ({ timesheetId, projectId, taskId }: CostTypeSelectProps) => {
  const result = useGetDimensions({
    timesheetId,
    projectId,
    taskId,
  });
  const data = getDimension(result.data, 'costType')?.values;
  return { ...result, data };
};

export const SelectCostType = buildDefaultSelect<DimensionValueType, CostTypeSelectProps>(useQuery, {
  testid: 'Select.CostType',
  emptyLabel: msg`Select cost type`,
});
