import { Trans, useLingui } from '@lingui/react/macro';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import Button from '@web/components/Shared/Button';
import Dialog from '@web/components/Shared/Dialog';
import Form from 'carbon-react/lib/components/form';
import { Option, Select } from 'carbon-react/lib/components/select';
import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DATA_RETENTION_OPTIONS } from '../../constants/SelectOptions';
import { RetentionModalContext } from './hooks/useRetentionModal';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]};
`;

const FieldContainer = styled.div`
  width: 240px;
`;

export interface RetentionModalProps {
  data: {
    title: string;
    selectLabel: string;
    type: string;
    retention?: number;
    action: (retention: number) => void;
    editMode?: boolean;
  };
  onHide: () => void;
}

function useGetDefaultRetention() {
  const context = useContext(RetentionModalContext);

  if (!context) {
    throw new Error('useGetDefaultRetention must be used within a RetentionModalProvider');
  }

  const { data: company } = useGetCurrentCompany();

  const { activitiesRetention = null, calendarRetention = null, emailRetention = null } = company || {};

  switch (context.options?.type) {
    case 'email':
      return emailRetention;
    case 'calendar':
      return calendarRetention;
    case 'activities':
      return activitiesRetention;
    default:
      return null;
  }
}

const RetentionModal = () => {
  const context = useContext(RetentionModalContext);

  if (!context) {
    throw new Error('useGetDefaultRetention must be used within a RetentionModalProvider');
  }
  const { t } = useLingui();
  const { options, retention, closeModal } = context;

  const defaultRetention = useGetDefaultRetention();
  const [retentionPeriod, setRetentionPeriod] = useState<null | number>(retention || defaultRetention || null);

  const retentionSelectOptions = useMemo(() => {
    const safeDefault = typeof defaultRetention === 'number' ? defaultRetention : Number.parseInt(defaultRetention || '0', 10);
    return DATA_RETENTION_OPTIONS.filter((o) => Number.parseInt(o, 10) <= safeDefault);
  }, [defaultRetention]);

  const daysText = t`days`;

  if (!options) {
    return null;
  }

  return (
    <Dialog disableAutoFocus onCancel={closeModal} open title={options.title}>
      <Form
        saveButton={
          <Button
            buttonType={{
              default: 'primary',
              embedded: 'secondary',
            }}
            onClick={() => {
              if (retentionPeriod === null) return;
              options.onAction?.(retentionPeriod);
              closeModal();
            }}
            ml={1}
          >
            {options.isEditing ? <Trans>Save</Trans> : <Trans>Continue</Trans>}
          </Button>
        }
        leftSideButtons={
          <Button
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            onClick={closeModal}
          >
            <Trans>Cancel</Trans>
          </Button>
        }
      >
        <Root>
          <Trans>
            Sage Intelligent Time retains data from the account(s) you connect to help improve its capabilities to predict how activities
            are assigned. Reducing the retention period of data may impact the quality of these predictions and consequently the accuracy
            and the time it takes for you to complete your timesheets. You may change this setting at any time.
          </Trans>

          <FieldContainer>
            <Select
              id="data-retention-select"
              label={options.fieldLabel}
              name="data-retention-select"
              onChange={(e) => {
                const value = Number.parseInt(e.target.value);
                setRetentionPeriod(Number.isNaN(value) ? null : value);
              }}
              value={`${retentionPeriod}`}
            >
              {retentionSelectOptions.map((option) => (
                <Option key={option} text={`${option} ${daysText}`} value={option} />
              ))}
            </Select>
          </FieldContainer>
        </Root>
      </Form>
    </Dialog>
  );
};

export default RetentionModal;
