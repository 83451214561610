import { useLingui } from '@lingui/react/macro';
import { loadingChunkRetry } from '@sit/client-shared';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '../components/ErrorBoundary';

const ExternalAuth = lazy(() => loadingChunkRetry(() => import('../containers/ExternalAuth/ExternalAuth')));

function ExternalAuthPage() {
  const { t } = useLingui();
  return (
    <>
      <Helmet>
        <title>{t`Authentication | Sage Intelligent Time`}</title>
      </Helmet>
      <ErrorBoundary>
        <Suspense fallback={null}>
          <ExternalAuth />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default ExternalAuthPage;
