import { css } from 'styled-components';

/**
 * Workaround https://github.com/Sage/carbon/issues/6471
 * @see https://jira.sage.com/browse/SIT-2184
 */
export const disableInputIconToggle = css`
  & [data-element='input-icon-toggle'] {
    display: none;
  }
`;
