import { Trans } from '@lingui/react/macro';
import Button from '@web/components/Shared/Button';
import { useNavigateBack } from '@web/hooks/use-navigate';
import styled from 'styled-components';
import NotFoundImage from '../../assets/img/not-found.png';

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`;

const LeftContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  max-width: 400px;
  width: 50%;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const Subtitle = styled.div`
  font-size: 16px;
`;

const RightContent = styled.div`
  max-width: 400px;
  width: 50%;
`;

const NotFoundPicture = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const NotFoundMessage = () => {
  const navigateBack = useNavigateBack();
  return (
    <Root>
      <LeftContent>
        <Title data-testid="404-title">
          <Trans>Sorry, this page isn't available.</Trans>
        </Title>
        <Subtitle>
          <Trans>The link you followed may be broken, or the page may have been removed.</Trans>
        </Subtitle>
        <Button buttonType="primary" onClick={() => navigateBack()}>
          <Trans>Go back</Trans>
        </Button>
      </LeftContent>
      <RightContent>
        <NotFoundPicture src={NotFoundImage} alt="Page not found" />
      </RightContent>
    </Root>
  );
};
