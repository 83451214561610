import { TimeClock } from '@sit/client-shared';
import { ClockDimensionsField, requiredDimensions } from '@web/components/TimeClock/ActiveClock/constants';

export function validateTimeClock(clock: TimeClock, fieldsToValidate: ClockDimensionsField[], requireEmployee = false) {
  if (requireEmployee) {
    if (!clock.employee?.id) return false;
  }

  for (const field of fieldsToValidate) {
    if (!requiredDimensions.includes(field)) {
      continue;
    }

    if (field === 'department') {
      if (!clock.dimensionsValues?.find((dimension) => dimension.dimensionObjectName === 'DEPARTMENT')) return false;
      else continue;
    }
    if (field === 'location') {
      if (!clock.dimensionsValues?.find((dimension) => dimension.dimensionObjectName === 'LOCATION')) return false;
      else continue;
    }

    if (field === 'costType') {
      if (!clock.dimensionsValues?.find((dimension) => dimension.dimensionObjectName === 'COSTTYPE')) return false;
      else continue;
    }

    if (!clock[field]) return false;
  }

  return true;
}
