import { msg } from '@lingui/core/macro';
import { DimensionValueType, useGetDimensions } from '@sit/client-shared';
import { SelectProps, buildDefaultSelect } from './buildSelect';
import { getDimension } from './helpers';

export interface DepartmentSelectProps extends SelectProps<DimensionValueType> {
  timesheetId?: string | null;
  companyId?: string;
  projectId: string | null | undefined;
}

const useQuery = ({ timesheetId, projectId, companyId }: DepartmentSelectProps) => {
  const result = useGetDimensions({
    projectId,
    companyId,
    timesheetId: timesheetId ?? undefined,
  });
  const data = getDimension(result.data, 'department')?.values;

  return { ...result, data };
};

export const SelectDepartment = buildDefaultSelect<DimensionValueType, DepartmentSelectProps>(useQuery, {
  testid: 'Select.Department',
  emptyLabel: msg`Select department`,
});
