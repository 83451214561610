import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { validate as isUuid } from 'uuid';

interface TimesheetStore {
  saving: boolean;
  synced: boolean;
}

export type TimesheetsStore = Record<string, TimesheetStore>;

const initialState: TimesheetsStore = {};

const timesheetsSlice = createSlice({
  name: 'timesheets',
  initialState,
  reducers: {
    setTimesheetSaving(state, action: PayloadAction<{ timesheetId: string; saving: boolean }>) {
      const { timesheetId, saving } = action.payload;
      // Protect against prototype pollution
      if (!isUuid(timesheetId)) return;
      if (state[timesheetId]) {
        state[timesheetId].saving = saving;
      } else {
        state[timesheetId] = { saving, synced: true };
      }
    },
    setSyncStatus(state, action: PayloadAction<{ timesheetId: string; synced: boolean }>) {
      const { timesheetId, synced } = action.payload;
      // Protect against prototype pollution
      if (!isUuid(timesheetId)) return;
      if (state[timesheetId]) {
        state[timesheetId].synced = synced;
      } else {
        state[timesheetId] = { saving: false, synced };
      }
    },
  },
});

export const { setTimesheetSaving, setSyncStatus } = timesheetsSlice.actions;
export default timesheetsSlice.reducer;
