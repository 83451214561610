import { useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

interface UseRouteTabOptions {
  enabled: boolean;
  defaultIsIndex?: boolean;
}

export const useRouteTab = (location: string, defaultTab: string, { enabled, defaultIsIndex = false }: UseRouteTabOptions) => {
  const [tabId, setTabId] = useState(defaultTab);

  const matched = useMatch(`${location}/*`);

  // Initialize tabId from the URL
  const { params = { '*': '' } } = matched ?? {};
  const navigate = useNavigate();

  const currentTab = params['*'] || defaultTab;

  useEffect(() => {
    if (currentTab) {
      setTabId(currentTab);
    }
  }, [currentTab]);

  const changeTab = useCallback(
    (tabId: string) => {
      setTabId(tabId);

      if (!enabled) return;

      if (defaultIsIndex && tabId === defaultTab) {
        return navigate(`${location}`, { replace: true });
      }

      navigate(`${location}/${tabId}`, { replace: true });
    },
    [defaultIsIndex, defaultTab, enabled, location, navigate],
  );

  return { tabId, changeTab };
};
