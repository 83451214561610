import { Trans, useLingui } from '@lingui/react/macro';
import { PatchApiTimeClocksIdBreaksBreakIdRequestBody, PostApiTimeClocksIdDimensionValueRequestBody, TimeClock } from '@sit/client-shared';
import Button from '@web/components/Shared/Button';
import Dialog from '@web/components/Shared/Dialog';
import Form from 'carbon-react/lib/components/form';
import { useState } from 'react';
import { OnFieldChangeFn } from '../ActiveClock/ClockField';
import { ClockDimensionsField } from '../ActiveClock/constants';
import ModalActions, { ModalActionKeys } from './ModalActions';
import ModalContent from './ModalContent';

export interface ModalContentProps {
  clock: TimeClock;
  isStaff?: boolean;
  isDraft?: boolean;
  onClose: () => void;
  onFieldChange: OnFieldChangeFn;
  dimensionFields?: ClockDimensionsField[];
  onAction: (action: ModalActionKeys, value: TimeClock) => void;
  onBreakChange?: (id: string, body: PatchApiTimeClocksIdBreaksBreakIdRequestBody) => void;
  onBreakDelete?: (id: string) => void;
  onDimensionValueChange: (payload: PostApiTimeClocksIdDimensionValueRequestBody) => void;
  defaultTab?: 'dimensions' | 'breaks';
  disabledDimensions: string[];
}

const TimeClockModal = ({
  clock,
  isStaff,
  isDraft,
  onClose,
  onFieldChange,
  onAction,
  dimensionFields,
  onDimensionValueChange,
  onBreakChange,
  onBreakDelete,
  defaultTab = 'breaks',
  disabledDimensions,
}: ModalContentProps) => {
  const { t } = useLingui();
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    const animationTimeout = setTimeout(() => {
      onClose();
      clearTimeout(animationTimeout);
    }, 300);
  };

  const title = isDraft ? t`Create time clock` : !clock.readonly && isStaff ? t`Edit time clock` : t`View time clock`;

  return (
    <Dialog open={isOpen} complexForm title={title} onCancel={() => handleClose()}>
      <Form
        stickyFooter
        height="550px"
        saveButton={
          <ModalActions
            key={clock.clockOutTime ?? 'running'}
            clock={clock}
            isStaff={isStaff}
            isDraft={isDraft}
            onAction={(action) => onAction(action, clock)}
          />
        }
        leftSideButtons={
          <Button
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            onClick={() => handleClose()}
          >
            <Trans>Close</Trans>
          </Button>
        }
      >
        <ModalContent
          isStaff={isStaff}
          isDraft={isDraft}
          clock={clock}
          onFieldChange={onFieldChange}
          onBreakChange={onBreakChange}
          onBreakDelete={(id) => onBreakDelete?.(id)}
          onDimensionValueChange={onDimensionValueChange}
          dimensionFields={dimensionFields}
          defaultTab={defaultTab}
          disabledDimensions={disabledDimensions}
        />
      </Form>
    </Dialog>
  );
};

export default TimeClockModal;
