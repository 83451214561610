import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';
import { MessageDescriptor } from '@lingui/core';

export type PillTextType =
  | 'Active'
  | 'Approved'
  | 'Declined'
  | 'Draft'
  | 'Error'
  | 'Inactive'
  | 'InvitationSent'
  | 'Invoiced'
  | 'NoData'
  | 'NoInfo'
  | 'NotInvited'
  | 'Submitted'
  | 'PartiallyApproved'
  | 'PartiallyDeclined'
  | 'Processing';

export type StatusPillColors = 'success' | 'error' | 'warning' | 'info' | 'default';

const pillMessages: Record<PillTextType, MessageDescriptor> = {
  Active: msg`Active`,
  Approved: msg`Approved`,
  Declined: msg`Declined`,
  Draft: msg`Draft`,
  Error: msg`Error`,
  Inactive: msg`Inactive`,
  InvitationSent: msg`Invitation sent`,
  Invoiced: msg`Invoiced`,
  NoData: msg`No data`,
  NoInfo: msg`No info`,
  NotInvited: msg`Not invited`,
  Submitted: msg`Submitted`,
  PartiallyApproved: msg`Partially approved`,
  PartiallyDeclined: msg`Partially declined`,
  Processing: msg`Processing`,
};

export const usePillLocales = (text: PillTextType) => {
  const { i18n } = useLingui();

  return i18n._(pillMessages[text]);
};
