import { Trans, useLingui } from '@lingui/react/macro';
import { PatchApiTimeClocksIdBreaksBreakIdRequestBody, TimeClockBreak } from '@sit/client-shared';
import { stringWithFallback } from '@web/helpers/label';
import { ActionPopover, ActionPopoverItem } from 'carbon-react/lib/components/action-popover';
import Box from 'carbon-react/lib/components/box';
import {
  FlatTable,
  FlatTableBody,
  FlatTableCell,
  FlatTableHead,
  FlatTableHeader,
  FlatTableProps,
  FlatTableRow,
} from 'carbon-react/lib/components/flat-table';
import styled, { css } from 'styled-components';
import DateTimeInput from '../Shared/DateTimeInput';
import { EditableBreak, getBreakLines, getEditableBreaks } from './hooks/useBreaks';

interface StyledBreakTableProps extends FlatTableProps {
  fullWidth: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing * 2}px;
`;

const fullWidthStyle = css`
  width: 100%;
`;

const defaultWidthStyle = css`
  margin-left: ${({ theme }) => theme.spacing * 10}px;
  max-width: 500px;
`;

const BreakTable = styled<(props: StyledBreakTableProps) => React.JSX.Element>(FlatTable)`
  ${({ fullWidth }) => (fullWidth ? fullWidthStyle : defaultWidthStyle)}
`;

interface TimeClockBreaksTableProps {
  breaks: TimeClockBreak[];
  fullWidth?: boolean;
  editable?: boolean;
  onBreakDelete?: (id: string) => void;
  onBreakChange?: (id: string, body: PatchApiTimeClocksIdBreaksBreakIdRequestBody) => void;
}

const ViewContent = ({ breaks }: { breaks: TimeClockBreak[] }) => {
  const breakLines = getBreakLines(breaks);
  return (
    <>
      {breakLines.map((item) => (
        <FlatTableRow key={item.id}>
          <FlatTableCell>{item.breakBegin}</FlatTableCell>
          <FlatTableCell>{item.breakEnd}</FlatTableCell>
          <FlatTableCell>{item.breakTime}</FlatTableCell>
        </FlatTableRow>
      ))}
      {breakLines.length === 0 && (
        <FlatTableRow>
          <FlatTableCell colspan={3}>
            <Trans>No breaks recorded for this time clock</Trans>
          </FlatTableCell>
        </FlatTableRow>
      )}
    </>
  );
};

interface EditContentProps {
  breaks: TimeClockBreak[];
  onBreakChange?: (id: string, body: PatchApiTimeClocksIdBreaksBreakIdRequestBody) => void;
  onBreakDelete?: (id: string) => void;
}

const EditContent = ({ breaks, onBreakChange, onBreakDelete }: EditContentProps) => {
  const { t } = useLingui();
  const breakLines = getEditableBreaks(breaks);

  const handleBreakChange = (timeBreak: EditableBreak, field: keyof PatchApiTimeClocksIdBreaksBreakIdRequestBody) => (dateTime: Date) => {
    onBreakChange?.(timeBreak.id, {
      [field]: dateTime.toISOString(),
    });
  };

  return (
    <>
      {breakLines.map((item) => (
        <FlatTableRow key={item.id}>
          <FlatTableCell pt={3}>
            <DateTimeInput value={item.breakBegin} onChange={handleBreakChange(item, 'startTime')} />
          </FlatTableCell>
          <FlatTableCell pt={3}>
            {item.breakEnd ? <DateTimeInput value={item.breakEnd} onChange={handleBreakChange(item, 'endTime')} /> : stringWithFallback()}
          </FlatTableCell>
          <FlatTableCell pt={3}>{item.breakTime}</FlatTableCell>
          <FlatTableCell pt={3}>
            <ActionPopover>
              <ActionPopoverItem onClick={() => onBreakDelete?.(item.id)}>{t`Delete break`}</ActionPopoverItem>
            </ActionPopover>
          </FlatTableCell>
        </FlatTableRow>
      ))}
      {breakLines.length === 0 && (
        <FlatTableRow>
          <FlatTableCell colspan={3}>
            <Trans>No breaks recorded for this time clock</Trans>
          </FlatTableCell>
        </FlatTableRow>
      )}
    </>
  );
};

const TimeClockBreaksTable = ({ breaks, fullWidth = false, editable = false, onBreakChange, onBreakDelete }: TimeClockBreaksTableProps) => {
  return (
    <Root>
      <BreakTable fullWidth={fullWidth} colorTheme="transparent-white">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Break begin</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Break end</Trans>
              </Box>
            </FlatTableHeader>
            <FlatTableHeader>
              <Box justifyContent="space-between" alignItems="center" display="flex">
                <Trans>Break time</Trans>
              </Box>
            </FlatTableHeader>
            {editable && <FlatTableHeader />}
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>
          {editable ? (
            <EditContent breaks={breaks} onBreakChange={onBreakChange} onBreakDelete={onBreakDelete} />
          ) : (
            <ViewContent breaks={breaks} />
          )}
        </FlatTableBody>
      </BreakTable>
    </Root>
  );
};

export default TimeClockBreaksTable;
