import { useShowIntacctId } from '@web/api/show-intacct-id';
import { OptionProps } from 'carbon-react/lib/components/select';
import { useCallback } from 'react';
import { mapOptionWithExternalId } from '../helpers/mapOptionWithExternalId';

export function useMapOptions<T extends { name: string | null; externalId?: string | null; id: string }>() {
  const showIntacctId = useShowIntacctId();
  return useCallback<(option: T) => OptionProps>(
    (option) => mapOptionWithExternalId(option, { showExternalId: showIntacctId }),
    [showIntacctId],
  );
}
