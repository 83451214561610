import { DimValue, TimeClock } from '@sit/client-shared';
import { ConditionalPick, Simplify } from 'type-fest';

export type TimeClockEditableFields = ConditionalPick<TimeClock, DimValue | TimeClock['timeType'] | undefined>;
export type TimeClockEditableFieldsKeys = keyof TimeClockEditableFields;

export type ClockDimensionsField = Simplify<
  TimeClockEditableFieldsKeys | 'billable' | 'costType' | 'description' | 'department' | 'location' | 'notes'
>;
export interface TimeClockDimensionField {
  type: ClockDimensionsField;
  objectName: string;
  required: boolean;
}

export const requiredDimensions: ClockDimensionsField[] = ['client', 'project', 'task', 'item'];
export const timeClockDimensions: ClockDimensionsField[] = [
  'billable',
  'client',
  'department',
  'description',
  'item',
  'location',
  'notes',
  'project',
  'task',
  'timeType',
];

export const toTimeClockFields = (fields: ClockDimensionsField[], disabledDimensions: string[] = []): TimeClockDimensionField[] => {
  const disabledList = new Set(disabledDimensions.map((dimension) => dimension.slice(0, -1)));

  return fields.map((type) => {
    const objectName = type === 'client' ? 'CUSTOMER' : type.toUpperCase();

    return {
      type,
      objectName,
      required: requiredDimensions.includes(type),
      disabled: disabledList.has(type),
    };
  });
};

export const timeClockFields: TimeClockDimensionField[] = toTimeClockFields(timeClockDimensions);
