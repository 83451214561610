import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';
import { useCanListMyTimesheets } from '@sit/client-shared';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import Box from 'carbon-react/lib/components/box';
import styled from 'styled-components';
import { PageContent, PageHeader } from '../../components/Page';
import { CreateTimesheetButton } from './components/CreateTimesheetButton';
import { useIsStaff, useTimesheetModals } from './hooks';

const Root = styled(PageContent)`
  min-height: 100%;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
`;

interface TimesheetsPageProps {
  children: React.ReactNode;
}

export const TimesheetsPage = ({ children }: TimesheetsPageProps) => {
  const { data: company } = useGetCurrentCompany();
  const managedAtEntityLevel = !!company?.managedAtEntityLevel;
  const currentEntity = useCurrentEntity();
  const isTopLevel = !!currentEntity && currentEntity.intacctEntityId == null;
  const canListMyTimesheets = useCanListMyTimesheets();
  const { i18n } = useLingui();
  const staff = useIsStaff();
  const cannotCreateDueToPermissions = !(canListMyTimesheets || staff);

  // Disable create timesheet button if user cannot view their own timesheets (if they don't have an employee record)
  // and they are trying to view My timesheets.
  const disableCreateTimesheet = cannotCreateDueToPermissions || (isTopLevel && managedAtEntityLevel);
  const { openNewTimesheetModal } = useTimesheetModals();

  return (
    <Root>
      <PageHeader data-cy="timesheets-title" title={i18n._(staff ? msg`Staff timesheets` : msg`My timesheets`)}>
        {!cannotCreateDueToPermissions && <CreateTimesheetButton disabled={disableCreateTimesheet} onClick={openNewTimesheetModal} />}
      </PageHeader>
      <Box>{children}</Box>
    </Root>
  );
};
