import { Trans, useLingui } from '@lingui/react/macro';
import Form from 'carbon-react/lib/components/form';
import Message from 'carbon-react/lib/components/message';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';
import PublicPassword from '../../components/Shared/Public/PublicPassword';
import PublicTextBox from '../../components/Shared/Public/PublicTextBox';

const ErrorWrapper = styled.div`
  margin-top: 20px;
`;

export interface LoginFormValues {
  company: string;
  username: string;
  password: string;
}

interface LoginFormProps {
  errorMsg?: string;
  loading: boolean | undefined;
  disabled: boolean | undefined;
  initialValues?: LoginFormValues;
  onSubmit: (values: LoginFormValues) => void;
}

const INITIAL_VALUES: LoginFormValues = {
  company: '',
  username: '',
  password: '',
};

const useValidate = () => {
  const { t } = useLingui();
  return useCallback(
    (values: LoginFormValues) => {
      const errors: Partial<LoginFormValues> = {};

      if (!values.company) {
        errors.company = t`Required`;
      }

      if (!values.username) {
        errors.username = t`Required`;
      }

      if (!values.password) {
        errors.password = t`Required`;
      }

      return errors;
    },
    [t],
  );
};

const LoginForm = ({ errorMsg, onSubmit, disabled, loading, initialValues = INITIAL_VALUES }: LoginFormProps) => {
  const { t } = useLingui();
  const validate = useValidate();
  const { handleSubmit, values, errors, handleChange } = useFormik<LoginFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { company, username, password } = values;

  return (
    <PublicForm
      title={t`Intelligent Time`}
      footerComponent={
        <Link data-pendo-id="forgot-password" to="/forgot-password" data-testid="forgot-password">
          <Trans>Forgot your password?</Trans>
        </Link>
      }
    >
      <Form
        saveButton={
          <PublicButton
            data-pendo-id="login"
            buttonType="primary"
            disabled={disabled}
            label={t`Log in`}
            loading={loading}
            key="login"
            type="submit"
          />
        }
        onSubmit={handleSubmit}
      >
        <PublicTextBox
          disabled={disabled}
          id="company"
          label={t`Intacct company ID`}
          name="company"
          onChange={handleChange}
          value={company}
          error={errors?.company}
          data-testid="company-id"
        />
        <PublicTextBox
          id="username"
          label={t`Intacct user ID`}
          disabled={disabled}
          name="username"
          onChange={handleChange}
          value={username}
          error={errors?.username}
          data-testid="username"
        />
        <PublicPassword
          id="password"
          label={t`Password`}
          disabled={disabled}
          name="password"
          onChange={handleChange}
          value={password}
          data-testid="password"
          error={errors?.password}
        />

        {errorMsg && (
          <ErrorWrapper data-testid="Login-error-msg">
            <Message variant="error">{errorMsg}</Message>
          </ErrorWrapper>
        )}
      </Form>
    </PublicForm>
  );
};

export default LoginForm;
