import { TimeClock, useUserDateFormat } from '@sit/client-shared';
import { dateWithFallback, numberWithFallback } from '@web/helpers/label';
import { ClockDimensionsField, toTimeClockFields } from '../../ActiveClock/constants';
import { isClockRunning } from '../../helpers/activeClockState';
import { useDimensionValueFields } from './useDimensionvalueFields';

const getClockDuration = (clock: TimeClock) => {
  const clockIn = new Date(clock.clockInTime);
  const clockOut = !clock.clockOutTime ? new Date() : new Date(clock.clockOutTime);

  return {
    clockIn,
    clockOut,
    totalHours: (clockOut.getTime() - clockIn.getTime()) / 1000,
  };
};

export interface ModalContentFieldItem {
  clock: TimeClock;
  readonly: boolean;
  type: ClockDimensionsField;
  objectName: string;
  required: boolean;
}

export const useModalContent = (
  clock: TimeClock,
  dimensionFields: ClockDimensionsField[],
  disabledDimensions: string[],
  isStaff = false,
  isDraft = false,
) => {
  const dateFormat = useUserDateFormat();
  const { clockIn, totalHours } = getClockDuration(clock);

  const readonly = clock.readonly || (isStaff || isDraft ? false : !isClockRunning(clock));
  const displayStaffControls = !clock.readonly && isStaff;

  const dimensionValueFields = useDimensionValueFields(clock, disabledDimensions);

  const fields = toTimeClockFields(dimensionFields, disabledDimensions).map<ModalContentFieldItem>((field) => ({
    ...field,
    clock,
    readonly,
  }));

  return {
    fields,
    readonly,
    dimensionValueFields,
    displayStaffControls,
    timeClockDate: dateWithFallback(clockIn, dateFormat),
    totalHours: numberWithFallback(totalHours),
  };
};
