// This file is effectively replacing the selectors folder
// It will allow us to use a combination of react-query and redux
import {
  GetApiClustersForTimesheetResponse,
  GetApiTimesheetsByUuidEntriesResponse,
  TimesheetType,
  isTokenExpired,
  useGetTimesheet,
  useGetTimesheetEntries,
  useIntacctCompanyId,
  useUserRole,
  type CompanyType,
  type GetApiDimensionsQueryParamsUpdated,
  type GetApiDimensionsResponse,
  type GetApiTasksError,
  type GetApiTasksResponse,
} from '@sit/client-shared';
import { TenantTypes } from '@sit/core';
import type { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { useGetDimensions } from '@web/api/dimensions/use-get-dimensions';
import { useShowIntacctId } from '@web/api/show-intacct-id';
import { useGetTasks } from '@web/api/tasks/use-get-tasks';
import { useGetUser } from '@web/api/users/use-get-user';
import { adminManagerRegEx, internalTenantTypeRegEx } from '@web/constants/regularExpressions';
import { includeIntacctId } from '@web/helpers/utils';
import compact from 'lodash/compact';
import { useCallback, useMemo } from 'react';
import type { SharedUnionFieldsDeep } from 'type-fest';
import { selectClusterAssignModal } from '../selectors';
import {
  filterAssignedClusters,
  filterUnassignedClusters,
  groupAndSortClusters,
  includeIntacctIdInClusterDimensionNames,
  includeIntacctIdInEntryDimensionNames,
} from '../selectors/helpers';
import { useAppSelector } from '../store';

// company
export function useCompanyEntities() {
  const { data: company } = useGetCurrentCompany();
  return company?.entities;
}
export function useDisableMFASwitch() {
  const isAuthenticated = useIsAuthenticated();
  const { data: company } = useGetCurrentCompany();
  const intacctCompanyId = useIntacctCompanyId({
    enabled: isAuthenticated,
  });
  if (company?.tenantType === TenantTypes.debug) return true;
  return !intacctCompanyId;
}

export function useIsInternalTenant() {
  const { data: company } = useGetCurrentCompany();
  return internalTenantTypeRegEx.test(company?.tenantType || 'debug');
}

// timesheets

function selectIncludeIntacctId(data: GetApiTimesheetsByUuidEntriesResponse) {
  return data.map((entry) => includeIntacctIdInEntryDimensionNames(entry));
}

export function useTimesheetEntriesData(timesheetId: string) {
  const showIntacctId = useShowIntacctId();
  const select = useCallback(
    (data: GetApiTimesheetsByUuidEntriesResponse) => (showIntacctId ? selectIncludeIntacctId(data) : data),
    [showIntacctId],
  );
  return useGetTimesheetEntries(
    {
      pathParams: {
        id: timesheetId,
      },
    },
    { select },
  );
}
export function useEntryById(timesheetId: string, entryId?: string) {
  const showIntacctId = useShowIntacctId();
  const select = useCallback(
    (data: GetApiTimesheetsByUuidEntriesResponse) => {
      const entry = data.find((e) => e.id === entryId);
      if (!entry) return null;
      if (!showIntacctId) return entry;
      return includeIntacctIdInEntryDimensionNames(entry);
    },
    [showIntacctId, entryId],
  );
  return useGetTimesheetEntries(
    {
      pathParams: {
        id: timesheetId,
      },
    },
    {
      select,
    },
  );
}

// Timesheet
export function useTimesheetSaving(timesheetId: string) {
  return useAppSelector((state) => state.timesheets[timesheetId]?.saving) ?? false;
}
export function useTimesheetSyncStatus(timesheetId: string) {
  return useAppSelector((state) => state.timesheets[timesheetId]?.synced) ?? false;
}
export function useTimesheetStartDate(id: string) {
  const startDate = useGetTimesheet(id).data?.startDate;
  return startDate ? startDate.substring(0, 10) : '';
}
export function useTimesheetEndDate(id: string) {
  const endDate = useGetTimesheet(id).data?.endDate;
  return endDate ? endDate.substring(0, 10) : '';
}
export function useTimesheetDescription(id: string) {
  return useGetTimesheet(id).data?.description;
}
export function useTimesheetStatus(id: string) {
  return useGetTimesheet(id).data?.status || 'NO_STATUS';
}
export function useSubmitButtonDisabled(timesheetId: string) {
  const timesheetStatus = useTimesheetStatus(timesheetId);
  const { data: entriesData } = useGetTimesheetEntries({
    pathParams: {
      id: timesheetId,
    },
  });
  return /^(INVOICED)$/.test(timesheetStatus) || !entriesData || Object.keys(entriesData).length === 0;
}

// Dimensions

export function useTasksPerProjectId(
  timesheetId: string,
  projectId: string | null | undefined,
  options?:
    | Omit<
        UseInfiniteQueryOptions<GetApiTasksResponse, GetApiTasksError, GetApiTasksResponse, GetApiTasksResponse, QueryKey>,
        'queryKey' | 'queryFn'
      >
    | undefined,
) {
  const showProject = useShowProject(timesheetId);
  return useGetTasks(
    {
      queryParams: {
        timesheetId,
        projectId: showProject && projectId ? projectId : undefined,
      },
    },
    options,
  );
}

export function useTasksPerProjectIdOfClusterAssignModal(timesheetId: string) {
  const clusterAssignModal = useAppSelector(selectClusterAssignModal);
  return useTasksPerProjectId(timesheetId, clusterAssignModal?.selectedValues?.projectId);
}

export function useDimensionsData(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const { data: dimensionsData = [] } = useGetDimensions(queryParams);
  const showIntacctId = useShowIntacctId();
  return useMemo(() => {
    if (!showIntacctId) return dimensionsData;
    return dimensionsData.map((d) => ({
      ...d,
      values: d.values?.map((v) => includeIntacctId(v)),
    }));
  }, [dimensionsData, showIntacctId]);
}

const findDepartmentDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'DEPARTMENT');
const findLocationDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'LOCATION');
const findCostTypeDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'COSTTYPE');
const findEmployeePositionDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'EMPLOYEEPOSITION');
const findLaborClassDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'LABORCLASS');
const findLaborShiftDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'LABORSHIFT');
const findLaborUnionDimension = (dimensionsData: GetApiDimensionsResponse) =>
  dimensionsData?.find((d) => d.integrationMeta?.objectName === 'LABORUNION');

export function useDepartmentDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findDepartmentDimension(dimensionsData);
}

export function useLocationDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findLocationDimension(dimensionsData);
}

export function useCostTypeDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findCostTypeDimension(dimensionsData);
}

export function useEmployeePositionDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findEmployeePositionDimension(dimensionsData);
}

export function useLaborClassDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findLaborClassDimension(dimensionsData);
}

export function useLaborShiftDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findLaborShiftDimension(dimensionsData);
}

export function useLaborUnionDimension(queryParams: GetApiDimensionsQueryParamsUpdated) {
  const dimensionsData = useDimensionsData(queryParams);
  return findLaborUnionDimension(dimensionsData);
}

export function useClusterEntry(timesheetId: string, cluster: { timesheet_entry_id?: string | null | undefined } | undefined) {
  const showIntacctId = useShowIntacctId();
  const { data: entriesData } = useGetTimesheetEntries({
    pathParams: {
      id: timesheetId,
    },
  });
  const clusterEntryId = cluster?.timesheet_entry_id;
  if (!clusterEntryId || !entriesData) return null;
  const entry = entriesData.find((e) => e.id === clusterEntryId);
  if (!showIntacctId || !entry) return entry || null;
  return {
    ...entry,
    client: entry.client ? includeIntacctId(entry.client) : undefined,
    project: entry.project ? includeIntacctId(entry.project) : undefined,
    task: entry.task ? includeIntacctId(entry.task) : undefined,
    item: entry.item ? includeIntacctId(entry.item) : undefined,
  };
}

export function useAssignedClusters(clusters: GetApiClustersForTimesheetResponse, timesheetId: string) {
  const showIntacctId = useShowIntacctId();
  const { data: entriesData } = useGetTimesheetEntries({
    pathParams: {
      id: timesheetId,
    },
  });
  return useMemo(() => {
    if (!showIntacctId) return groupAndSortClusters(filterAssignedClusters(clusters, entriesData, timesheetId));
    const updatedClusterData: GetApiClustersForTimesheetResponse = compact(clusters.map((c) => includeIntacctIdInClusterDimensionNames(c)));
    return groupAndSortClusters(filterAssignedClusters(updatedClusterData, entriesData, timesheetId));
  }, [clusters, entriesData, showIntacctId, timesheetId]);
}

export function useUnassignedClusters(clusters: GetApiClustersForTimesheetResponse) {
  const showIntacctId = useShowIntacctId();
  return useMemo(() => {
    if (!showIntacctId) return groupAndSortClusters(filterUnassignedClusters(clusters));
    const updatedClusterData = clusters.map((c) => includeIntacctIdInClusterDimensionNames(c));
    return groupAndSortClusters(filterUnassignedClusters(updatedClusterData));
  }, [clusters, showIntacctId]);
}

function useShowDim<D extends keyof SharedUnionFieldsDeep<CompanyType | TimesheetType>>(timesheetId: string | undefined, dim: D) {
  const { data: timesheetData } = useGetTimesheet(timesheetId);
  const { data: company } = useGetCurrentCompany();
  return timesheetData?.[dim] ?? !!company?.[dim];
}

/**
 * @param timesheetId `undefined` must only used when creating demo activity cards
 */
export function useShowCustomer(timesheetId: string | undefined) {
  return useShowDim(timesheetId, 'showCustom');
}

/**
 * @param timesheetId `undefined` must only used when creating demo activity cards
 */
function useShowProject(timesheetId: string | undefined) {
  return useShowDim(timesheetId, 'showProject');
}

/**
 * @param timesheetId `undefined` must only used when creating demo activity cards
 */
export function useShowDepartmentLocation(timesheetId: string | undefined) {
  return useShowDim(timesheetId, 'showDepartmentLocation');
}

/**
 * @param timesheetId `undefined` must only used when creating demo activity cards
 */
export function useShowDescriptionFirst(timesheetId: string | undefined) {
  return useShowDim(timesheetId, 'showDescriptionFirst');
}

export function useDimensionDisplayNames() {
  const {
    clientDisplayName = null,
    projectDisplayName = null,
    taskDisplayName = null,
    itemDisplayName = null,
    departmentDisplayName = null,
    locationDisplayName = null,
    costTypeDisplayName = null,
    employeePositionDisplayName = null,
    laborClassDisplayName = null,
    laborShiftDisplayName = null,
    laborUnionDisplayName = null,
  } = useGetCurrentCompany().data || {};
  return {
    clientDisplayName,
    projectDisplayName,
    taskDisplayName,
    itemDisplayName,
    departmentDisplayName,
    locationDisplayName,
    costTypeDisplayName,
    employeePositionDisplayName,
    laborClassDisplayName,
    laborShiftDisplayName,
    laborUnionDisplayName,
  };
}

export function useHasAccessToUserManagement() {
  const role = useUserRole();
  return adminManagerRegEx.test(role || 'NO_USER_ROLE');
}

// Companies
export function useAttachmentRights() {
  const { data: company } = useGetCurrentCompany();
  const { data: user } = useGetUser();
  return {
    canEditAttachments: !!company?.canEditAttachments && !!user?.canEditAttachments,
    canDownloadAttachments: !!company?.canDownloadAttachments && !!user?.canDownloadAttachments,
    canDeleteAttachments: !!company?.canDeleteAttachments && !!user?.canDeleteAttachments,
    canListAttachments: !!company?.canListAttachments && !!user?.canListAttachments,
  };
}
// auth
export function useIsAuthenticated() {
  // Grace period for token expiration in milliseconds
  const requestGracePeriod = 1000;

  return useAppSelector((state) => state.user.token != null && !isTokenExpired(state.user.token, requestGracePeriod));
}
