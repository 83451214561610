import { Trans } from '@lingui/react/macro';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import Loader from 'carbon-react/lib/components/loader';
import CarbonPreview from 'carbon-react/lib/components/preview';
import Textbox from 'carbon-react/lib/components/textbox';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'usehooks-ts';
import { EmployeeSelector } from '../../containers/Timesheet/TopComponents/EmployeesSelector';

const ChevronIcon = styled(Icon)<{ open: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  padding: ${({ theme }) => theme.space[3]};
`;

const Title = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  font-size: 24px;
  font-weight: 700;
`;

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 400px;
  gap: ${({ theme }) => theme.space[1]};
`;
const FieldLabel = styled.div`
  color: ${({ theme }) => theme.palette.blackOpacity(0.9)};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
const FieldContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > * {
    flex: 1;
  }
`;

export interface TimesheetInformationProps {
  timesheetId: string;
  readonly?: boolean;
  employeeSelectLabel?: string;
  description?: string;
  employeeId: string | undefined;
  disabled?: boolean;
  loading?: boolean;
  onChange?: (data: Partial<TimesheetInformationData>) => void;
  updateInProgress?: boolean;
  attachment?: AttachmentData;
  editableDescription?: boolean;
}

interface TimesheetInformationData {
  description?: string;
  externalEmployeeId?: string;
}

interface AttachmentData {
  count?: number | null;
  button: React.ReactNode;
}

const TimesheetInfo = ({
  timesheetId,
  readonly = false,
  employeeId: initialEmployeeId,
  employeeSelectLabel,
  description: initialDescription,
  disabled,
  loading,
  onChange,
  updateInProgress,
  editableDescription,
  attachment,
}: TimesheetInformationProps) => {
  const [open, setOpen] = useState(true);

  const [initialLoaded, setInitialLoaded] = useState(false);
  const [description, setDescription] = useState<string | undefined>(initialDescription);
  const debouncedDescription = useDebounce<string | undefined>(description, 500);

  const [employeeId, setEmployeeId] = useState<string | undefined>(initialEmployeeId);

  const handleChangeEmployeeId = (employeeId: string) => {
    setEmployeeId(employeeId);
    onChange?.({
      externalEmployeeId: employeeId,
    });
  };

  useEffect(() => {
    // Check if initialDescription is provided and not loaded yet
    if (initialDescription !== undefined && initialEmployeeId !== undefined && !initialLoaded) {
      setEmployeeId(initialEmployeeId);
      setDescription(initialDescription);
      setInitialLoaded(true);
    }
  }, [initialDescription, initialLoaded, initialEmployeeId]);

  useEffect(() => {
    // Use debouncedDescription to avoid unnecessary updates
    if (debouncedDescription === undefined || debouncedDescription === initialDescription) {
      return;
    }
    onChange?.({
      description: debouncedDescription || '',
    });
  }, [debouncedDescription]);

  const descriptionIsReadonly = !editableDescription && readonly;

  return (
    <Root>
      <Title>
        <IconButton aria-label="icon-button" onClick={() => setOpen((o) => !o)}>
          <ChevronIcon fontSize="medium" open={open} type="chevron_down" />
        </IconButton>
        <Trans>Timesheet information</Trans>
        {updateInProgress && <Loader />}
      </Title>
      {open && (
        <Container>
          <Field>
            <FieldLabel>
              <Trans>Employee</Trans>
            </FieldLabel>
            <FieldContent>
              <EmployeeSelector
                timesheetId={timesheetId}
                disabled={disabled}
                loading={loading}
                employeeId={employeeId ?? ''}
                onChange={handleChangeEmployeeId}
                readonly={readonly}
                label={employeeSelectLabel}
              />
            </FieldContent>
          </Field>
          <Field>
            <FieldLabel>
              <Trans>Description</Trans>
            </FieldLabel>
            <FieldContent>
              {descriptionIsReadonly && <span>{description}</span>}
              {!descriptionIsReadonly && (
                <Textbox
                  data-cy="timesheet-description"
                  disabled={disabled}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              )}
            </FieldContent>
          </Field>
          {attachment && (
            <Field>
              <FieldLabel>
                <Trans>Attachments</Trans>
                {attachment.count != null ? ` (${attachment.count})` : ''}
              </FieldLabel>
              <FieldContent>{attachment.button}</FieldContent>
            </Field>
          )}
        </Container>
      )}
    </Root>
  );
};

export const Preview = () => {
  return (
    <Root>
      <Title>
        <IconButton aria-label="icon-button" onClick={() => undefined}>
          <ChevronIcon fontSize="medium" open={true} type="chevron_down" />
        </IconButton>
        <Trans>Timesheet information</Trans>
      </Title>
      <Container>
        <Field>
          <FieldLabel>
            <CarbonPreview height="17px" width="150px" />
          </FieldLabel>
          <FieldContent>
            <CarbonPreview height="40px" width="400px" />
          </FieldContent>
        </Field>
        <Field>
          <FieldLabel>
            <CarbonPreview height="17px" width="150px" />
          </FieldLabel>
          <FieldContent>
            <CarbonPreview height="40px" width="400px" />
          </FieldContent>
        </Field>
        <Field>
          <FieldLabel>
            <CarbonPreview height="17px" width="150px" />
          </FieldLabel>
          <FieldContent>
            <CarbonPreview height="40px" width="400px" />
          </FieldContent>
        </Field>
      </Container>
    </Root>
  );
};

export default TimesheetInfo;
