import { TimeClock } from '@sit/client-shared';
import { DateChangeEvent } from 'carbon-react/lib/components/date';
import { TimeValue, ToggleValue } from 'carbon-react/lib/components/time';
import { addHours, addMilliseconds, addMinutes, addSeconds, format, parse } from 'date-fns';
import { OnFieldChangeFn } from '../../ActiveClock/ClockField';

const toTimeValue = (date: Date): TimeValue => {
  const [hours, minutes, period] = format(date, 'hh,mm,aa').split(',') as [string, string, ToggleValue];

  return {
    hours,
    minutes,
    period,
  };
};

const fromTimeValue = (baseDate: Date, { hours, minutes, period }: TimeValue): Date => {
  const hours24 = period === 'PM' ? Number(hours) + 12 : Number(hours);

  const newDate = addHours(baseDate, hours24);

  return addMinutes(newDate, Number(minutes));
};

export function getTimeClockDate(timeClock: TimeClock, onFieldChange: OnFieldChangeFn) {
  const clockIn = new Date(timeClock.clockInTime);
  const clockOut = !timeClock.clockOutTime ? undefined : new Date(timeClock.clockOutTime);

  const handleDateChange = ({ target }: DateChangeEvent) => {
    const clockInValue = toTimeValue(clockIn);
    const clockOutValue = clockOut ? toTimeValue(clockOut) : undefined;
    const rawValue = target.value.rawValue;

    if (!rawValue) {
      return;
    }

    const [clockInSeconds, clockInMilliseconds] = format(clockIn, 'ss,SSS').split(',') as [string, string];
    const [clockOutSeconds, clockOutMilliseconds] = clockOut ? (format(clockOut, 'ss,SSS').split(',') as [string, string]) : ['00', '000'];

    const newDate = parse(rawValue, 'yyyy-MM-dd', new Date());

    const newClockInTime = addSeconds(addMilliseconds(newDate, Number(clockInMilliseconds)), Number(clockInSeconds));
    const newClockOutTime = clockOutValue
      ? addSeconds(addMilliseconds(newDate, Number(clockOutMilliseconds)), Number(clockOutSeconds))
      : undefined;

    onFieldChange({
      clockInTime: fromTimeValue(newClockInTime, clockInValue).toISOString(),
      clockOutTime: newClockOutTime && clockOutValue ? fromTimeValue(newClockOutTime, clockOutValue).toISOString() : null,
    });
  };

  return {
    clockIn,
    clockOut,
    handleDateChange,
  };
}
