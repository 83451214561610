import styled from 'styled-components';
import moment from 'moment';
import { NotificationData } from '../notification-data';

const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;
const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }) => theme.palette.slateTint(20)};
`;

const Timestamp = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

export interface NotificationDetailsProps extends Pick<NotificationData, 'notification' | 'timestamp'> {
  variant?: 'default' | 'description';
}

export const NotificationDetails = ({ notification, timestamp, variant = 'default' }: NotificationDetailsProps) => {
  return (
    <Details>
      <Title>{notification.title}</Title>
      {variant === 'description' && <Description>{notification.body}</Description>}
      <Timestamp>{moment(timestamp).fromNow()}</Timestamp>
    </Details>
  );
};
