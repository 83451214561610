import { useIsAdmin } from '@sit/client-shared';
import { PRODUCTION_TENANT_TYPES, TenantTypes } from '@sit/core';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { useGetUser } from '@web/api/users/use-get-user';
import { BuildType, setBuildType } from '@web/redux/Development';
import { useCompanyEntities, useDisableMFASwitch, useIsAuthenticated } from '@web/redux/hooks';
import { selectBuildType } from '@web/redux/selectors';
import { useAppDispatch, useAppSelector } from '@web/redux/store';
import omit from 'lodash/omit';
import { useLocation, useParams } from 'react-router-dom';
import { handleParamsValidation } from '../helpers';

type PrivatePageStates = 'UNAUTHENTICATED' | 'INVALID_PARAMS' | 'UNPROVISIONED' | 'NEEDS_TO_SET_UP_MFA' | 'AUTHORIZED';

interface UsePrivateDataProps {
  paramsToValidate?: Record<string, string>;
}

const NON_PRODUCTION_TENANTS = Object.values(omit(TenantTypes, PRODUCTION_TENANT_TYPES));

export const usePrivateData = ({ paramsToValidate }: UsePrivateDataProps): PrivatePageStates => {
  const dispatch = useAppDispatch();
  const companyEntities = useCompanyEntities();
  const disableMFASwitch = useDisableMFASwitch();
  const { data: company } = useGetCurrentCompany();
  const isAdmin = useIsAdmin();
  const isAuthenticated = useIsAuthenticated();
  const { data: user } = useGetUser({
    enabled: isAuthenticated,
  });
  const buildType = useAppSelector(selectBuildType);
  const needsToSetUpMfa = buildType === BuildType.default && !!company?.mfaRequired && user && !user.twoFactorEnabled;
  const params = useParams();
  const location = useLocation();
  const shouldValidateParams = paramsToValidate && Object.keys(paramsToValidate).length > 0;
  const paramsAreValid = shouldValidateParams && handleParamsValidation(paramsToValidate, params);
  const { pathname, search } = location;

  if (!isAuthenticated) {
    return 'UNAUTHENTICATED';
  }

  if (shouldValidateParams && !paramsAreValid) {
    return 'INVALID_PARAMS';
  }

  if (needsToSetUpMfa && !(location.pathname.startsWith('/time-preferences') || disableMFASwitch)) {
    return 'NEEDS_TO_SET_UP_MFA';
  }

  if (companyEntities && isAdmin && sessionStorage.getItem('visited-advanced') !== 'true' && pathname !== '/time-preferences/advanced') {
    sessionStorage.setItem('visited-advanced', 'true');
    return 'UNPROVISIONED';
  }
  const searchParams = new URLSearchParams(search);
  const queryForceEmbedded = searchParams.get('force_embedded_mode') === 'true';

  if (
    buildType !== BuildType.embedded &&
    queryForceEmbedded &&
    company?.tenantType &&
    (NON_PRODUCTION_TENANTS as string[]).includes(company.tenantType)
  ) {
    dispatch(setBuildType(BuildType.embedded));
  }

  return 'AUTHORIZED';
};
