import { TextareaProps as CarbonTextareaProps } from 'carbon-react/lib/components/textarea';
import { useRef, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { default as SharedTextArea } from './index';

export interface TextareaProps extends CarbonTextareaProps {
  defaultValue?: string;
  onValueChangeDebounced?: (value: string) => void;
  validate?: FieldValidationHandler;
  debounceDelay?: number;
}

type FieldValidationHandler = (value: string, isDirty: boolean) => string | undefined;

function DebouncedTextArea({ onValueChangeDebounced, onChange, defaultValue, debounceDelay = 500, ...props }: TextareaProps) {
  const [isDirty, setDirty] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>();

  const lastDebouncedValue = useRef<string | undefined>();
  const debounced = useDebounceCallback((value: string) => {
    onValueChangeDebounced?.(value);
    lastDebouncedValue.current = value;
  }, debounceDelay);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounced(event.target.value);
    onChange?.(event);
  };

  return (
    <SharedTextArea
      {...props}
      ref={(ref) => {
        textAreaRef.current = ref;
      }}
      error={props.validate?.(textAreaRef.current?.value ?? '', isDirty)}
      onFocus={() => !isDirty && setDirty(true)}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  );
}

export default DebouncedTextArea;
