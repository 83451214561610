import { Trans, useLingui } from '@lingui/react/macro';
import { msg } from '@lingui/core/macro';
import Button from '@web/components/Shared/Button';
import Dialog from '@web/components/Shared/Dialog';
import Box from 'carbon-react/lib/components/box';
import { Checkbox } from 'carbon-react/lib/components/checkbox';
import Form from 'carbon-react/lib/components/form';
import { useState } from 'react';
import styled from 'styled-components';

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]};
`;
const HeadingMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
`;

interface DeleteAccountModalProps {
  closeModal: () => void;
  isReconnecting?: boolean;
  onOk: (deleteClusters: boolean) => void;
  type?: string;
}

const DeleteAccountModal = ({ closeModal, isReconnecting, onOk, type }: DeleteAccountModalProps) => {
  const { i18n, t } = useLingui();
  const [deleteClusters, setDeleteClusters] = useState(false);

  const handleDelete = () => {
    onOk(deleteClusters);
    closeModal();
  };

  return (
    <Dialog
      disableEscKey={false}
      enableBackgroundUI={false}
      onCancel={closeModal}
      open={true}
      showCloseIcon={true}
      title={i18n._(isReconnecting ? msg`Re-connect account` : msg`Delete account`)}
    >
      <Form
        saveButton={
          <Button
            buttonType={{
              default: 'primary',
              embedded: 'secondary',
            }}
            destructive
            onClick={handleDelete}
            ml={1}
          >
            {isReconnecting ? <Trans>Delete and re-connect account</Trans> : <Trans>Delete account</Trans>}
          </Button>
        }
        leftSideButtons={
          <Button
            buttonType={{
              default: 'tertiary',
              embedded: 'secondary',
            }}
            destructive
            onClick={closeModal}
          >
            <Trans>Cancel</Trans>
          </Button>
        }
      >
        <FormContent>
          <HeadingMessage>
            <strong>
              <Trans>Are you sure you want to delete this account?</Trans>
            </strong>
            <Trans>This cannot be undone.</Trans>
          </HeadingMessage>
          {isReconnecting ? (
            <Trans>
              In order to re-connect your account, you'll need to delete this connection and then connect your account again. This will
              delete all data associated with this time source.
            </Trans>
          ) : (
            <Trans>This will delete all data associated with this time source.</Trans>
          )}
          <Trans>
            Check the checkbox below to also delete the generated activity cards associated with this time source. If you choose not to
            delete them, you can request at a later date that they be deleted by contacting customer support.
          </Trans>
          {type === 'tracker' && (
            <Box>
              <Trans>
                You must also uninstall Time Assistant from your desktop computer as described in the{' '}
                <a
                  href="https://www.intacct.com/ia/docs/en_US/help_action/Sage_Intelligent_Time/Standalone/Time_sources/aa-TOC-data-sources.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>documentation</Trans>
                </a>
                .
              </Trans>
            </Box>
          )}
          <Checkbox
            checked={deleteClusters}
            value={'delete-clusters'}
            label={t`Delete activity cards`}
            name={'delete-cards'}
            onChange={(e) => setDeleteClusters(e.target.checked)}
          />
        </FormContent>
      </Form>
    </Dialog>
  );
};

export default DeleteAccountModal;
