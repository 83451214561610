import { Trans, useLingui } from '@lingui/react/macro';
import { TimeClock } from '@sit/client-shared';
import Button from '@web/components/Shared/Button';
import { validateTimeClock } from '@web/containers/TimeClocks/helpers/validate';
import { useClockVisibleFields } from '@web/containers/TimeClocks/hooks/useClockVisibleFields';
import Loader from 'carbon-react/lib/components/loader';
import { useState } from 'react';
import { isClockOnBreak, isClockRunning } from '../helpers/activeClockState';
import type { ClockActions } from '../types';

export type ModalActionKeys = Exclude<ClockActions, 'clock-in' | 'view-details'>;

interface ModalActionsProps {
  clock: TimeClock;
  isStaff?: boolean;
  isDraft?: boolean;
  onAction?: (action: ModalActionKeys) => void;
}

const ModalActions = (props: ModalActionsProps) => {
  if (props.isDraft) {
    return <DraftActions {...props} />;
  }

  if (!isClockRunning(props.clock)) {
    return <StoppedActions {...props} />;
  }

  return <RunningActions {...props} />;
};

export default ModalActions;

const DeleteButton = ({ onAction }: Pick<ModalActionsProps, 'onAction'>) => {
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = () => {
    if (onAction) {
      setDeleting(true);
      onAction?.('delete');
    }
  };

  return (
    <Button
      buttonType={{
        default: 'primary',
        embedded: 'secondary',
      }}
      onClick={() => handleDelete()}
      ml={1}
      destructive
    >
      {isDeleting ? <Loader isInsideButton /> : <Trans>Delete</Trans>}
    </Button>
  );
};

const BreakActions = ({ onAction, clock }: Pick<ModalActionsProps, 'onAction' | 'clock'>) => {
  const { t } = useLingui();
  const onBreak = isClockOnBreak(clock);
  const action = onBreak ? 'break-end' : 'break-start';
  const label = onBreak ? t`End break` : t`Start break`;

  return <Button onClick={() => onAction?.(action)}>{label}</Button>;
};

const RunningActions = ({ clock, onAction, isStaff = false }: ModalActionsProps) => {
  return (
    <>
      <BreakActions onAction={onAction} clock={clock} />
      <Button
        buttonType={{
          default: 'primary',
          embedded: 'secondary',
        }}
        onClick={() => onAction?.('clock-out')}
        ml={1}
      >
        <Trans>Clock out</Trans>
      </Button>
      {isStaff && <DeleteButton onAction={onAction} />}
    </>
  );
};

const DraftActions = ({ onAction, clock }: ModalActionsProps) => {
  const dimensionFields = useClockVisibleFields();
  return (
    <>
      <Button
        disabled={!validateTimeClock(clock, dimensionFields, true)}
        buttonType={{
          default: 'primary',
          embedded: 'secondary',
        }}
        onClick={() => onAction?.('create')}
        ml={1}
      >
        <Trans>Create</Trans>
      </Button>
    </>
  );
};

const StoppedActions = ({ onAction, isStaff }: ModalActionsProps) => {
  if (!isStaff) {
    return null;
  }

  return <DeleteButton onAction={onAction} />;
};
