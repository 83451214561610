import { useLingui } from '@lingui/react/macro';
import CarbonDateInput, { DateInputProps } from 'carbon-react/lib/components/date';
import I18nProvider from 'carbon-react/lib/components/i18n-provider';
import { enUS } from 'date-fns/locale';

const DateInput = ({ ...props }: DateInputProps) => {
  const { t } = useLingui();

  return (
    <I18nProvider
      locale={{
        date: {
          dateFnsLocale: () => enUS,
          ariaLabels: {
            previousMonthButton: () => t`Previous month`,
            nextMonthButton: () => t`Next month`,
          },
        },
      }}
    >
      <CarbonDateInput {...props} />
    </I18nProvider>
  );
};

export default DateInput;
