import { TimeClockBreak } from '@sit/client-shared';
import { durationWithFallback, timeWithFallback } from '@web/helpers/label';
import { intervalToDuration, parseISO } from 'date-fns';

export interface Break {
  id: string;
  breakBegin: string;
  breakEnd: string;
  breakTime: string;
}

export interface EditableBreak {
  id: string;
  breakBegin: Date;
  breakEnd: Date | undefined;
  breakTime: string;
}

export function toTimeClockBreak(breakItem: EditableBreak): TimeClockBreak {
  return {
    id: breakItem.id,
    startTime: breakItem.breakBegin.toISOString(),
    endTime: breakItem.breakEnd?.toISOString() ?? null,
  };
}

export const getBreakLines = (breaks: TimeClockBreak[]): Break[] => {
  return breaks.map((breakItem) => {
    const startTime = new Date(breakItem.startTime);
    const endTime = breakItem.endTime ? new Date(breakItem.endTime) : undefined;
    const totalTime = endTime ? intervalToDuration({ start: startTime, end: endTime }) : null;

    return {
      id: breakItem.id,
      breakBegin: timeWithFallback(startTime),
      breakEnd: timeWithFallback(endTime),
      breakTime: durationWithFallback(totalTime),
    };
  });
};

export const getEditableBreaks = (breaks: TimeClockBreak[]): EditableBreak[] => {
  return breaks.map((breakItem) => {
    const breakBegin = parseISO(breakItem.startTime);
    const breakEnd = breakItem.endTime ? parseISO(breakItem.endTime) : undefined;
    const totalTime = breakEnd ? intervalToDuration({ start: breakBegin, end: breakEnd }) : null;

    return {
      id: breakItem.id,
      breakBegin,
      breakEnd,
      breakTime: durationWithFallback(totalTime),
    };
  });
};

export const usIsOnBreak = (breaks: TimeClockBreak[]): boolean => {
  return breaks.some((breakItem) => !breakItem.endTime);
};
