import { format, type Duration } from 'date-fns';
import { formatLabelNumber, secondsToHoursFixed } from './time';
import { formatIntacctDate } from '@sit/client-shared';

const DEFAULT_EMPTY_FALLBACK = '-';

const DEFAULT_DURATION_FORMAT: (keyof Duration)[] = ['hours', 'minutes', 'seconds'];

export const stringWithFallback = (label?: string | number | null, fallback = DEFAULT_EMPTY_FALLBACK) =>
  label == null || label === '' ? fallback : `${label}`;

export const numberWithFallback = (label?: number | null, fallback = DEFAULT_EMPTY_FALLBACK) =>
  label != null ? secondsToHoursFixed(label) : fallback;

export const dateWithFallback = (date: Date | undefined, dateFormat: string, fallback = DEFAULT_EMPTY_FALLBACK) =>
  date ? formatIntacctDate(date, dateFormat) : fallback;
export const dayWithFallback = (date: Date | undefined, fallback = DEFAULT_EMPTY_FALLBACK) => (date ? format(date, 'EEEE') : fallback);
export const timeWithFallback = (date: Date | undefined, fallback = DEFAULT_EMPTY_FALLBACK) => (date ? format(date, 'hh:mmaaa') : fallback);

export interface DurationWithFallbackOptions {
  fallback?: string;
  format?: (keyof globalThis.Duration)[];
}

export const durationWithFallback = (
  duration: Duration | null,
  { fallback = DEFAULT_EMPTY_FALLBACK, format = DEFAULT_DURATION_FORMAT }: DurationWithFallbackOptions = {},
) => {
  if (duration === null) return fallback;

  const hours = (duration.hours ?? 0) + (duration.days || 0) * 24;
  duration.days = 0;
  duration.hours = hours;

  const values = format.map((key) => formatLabelNumber(duration[key]));

  return values.join(':');
};
