export function reportError(error: string | Error, customAttributes?: Record<string, newrelic.SimpleType> | undefined): void {
  if (import.meta.env.NODE_ENV !== 'production') return;
  window.newrelic?.noticeError(error, customAttributes);
}

const newRelicAttributes = ['userId'] as const;

type NewRelicAttributes = (typeof newRelicAttributes)[number];

export function setCustomAttribute(name: NewRelicAttributes, value: newrelic.SimpleType) {
  window.newrelic?.setCustomAttribute(name, value);
}

export function clearCustomAttributes() {
  for (const name of newRelicAttributes) {
    // TODO: Find a cleaner way to remove custom attributes
    window.newrelic?.setCustomAttribute(name, 0);
  }
}
