import { DimensionValueType, PostApiTimeClocksIdDimensionValueRequestBody, TimeClock } from '@sit/client-shared';
import SelectDimensionValue from '@web/components/Fields/Select/DimensionValue';
import { stringWithFallback } from '@web/helpers/label';
import Box from 'carbon-react/lib/components/box';
import Typography from 'carbon-react/lib/components/typography';

export type ChangeEventHandler = (payload: PostApiTimeClocksIdDimensionValueRequestBody) => void;

export interface DimensionValueFieldProps {
  objectName: string | undefined;
  label: string;
  id: string;
  emptyLabel: string;
  value: string | undefined;
  optionValue: string | undefined;
  clock: TimeClock;
  readonly?: boolean;
  onChange?: ChangeEventHandler;
  disabled?: boolean;
}

function DimensionValueField({
  clock,
  objectName,
  label,
  optionValue,
  id,
  emptyLabel,
  value,
  onChange,
  readonly = false,
  disabled,
}: DimensionValueFieldProps) {
  const handleChange = (data: DimensionValueType | undefined) => {
    if (!objectName) {
      return;
    }

    const payload: PostApiTimeClocksIdDimensionValueRequestBody = {
      dimensionObjectName: objectName,
      valueId: data?.id ?? null,
    };

    onChange?.(payload);
  };

  if (!objectName) {
    return null;
  }

  if (readonly) {
    return (
      <Box>
        <Typography variant="strong">{label}</Typography>
        <Typography truncate>{stringWithFallback(optionValue)}</Typography>
      </Box>
    );
  }

  return (
    <SelectDimensionValue
      width="100%"
      disabled={disabled}
      companyId={clock.companyId}
      timesheetId={clock.timesheetId ?? undefined}
      projectId={clock.project?.id}
      taskId={clock.task?.id}
      select={(d) => d.find(({ integrationMeta }) => integrationMeta?.objectName === objectName)?.values}
      data-testid={`${objectName}-${id}`}
      label={label}
      emptyLabel={emptyLabel}
      value={value}
      onChange={handleChange}
    />
  );
}

export default DimensionValueField;
