import { DimensionValueType, GetApiDimensionsQueryParamsUpdated, GetApiDimensionsResponse, useGetDimensions } from '@sit/client-shared';
import { Simplify } from 'type-fest';
import { SelectProps, buildDefaultSelect } from './buildSelect';

export type DimensionSelector = (data: GetApiDimensionsResponse) => DimensionValueType[] | undefined;

export type SelectDimensionValueProps = Simplify<
  SelectProps<DimensionValueType> &
    GetApiDimensionsQueryParamsUpdated & {
      select: DimensionSelector;
    }
>;

const useQuery = ({ timesheetId, companyId, projectId, taskId, select }: SelectDimensionValueProps) => {
  const result = useGetDimensions({
    projectId,
    companyId,
    taskId,
    timesheetId: timesheetId ?? undefined,
  });
  const data = result.data ? select(result.data) : undefined;

  return { ...result, data };
};

const SelectDimensionValue = buildDefaultSelect<DimensionValueType, SelectDimensionValueProps>(useQuery, {});

export default SelectDimensionValue;
