import { TextboxProps as CarbonTextBoxProps } from 'carbon-react/lib/components/textbox';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { default as SharedTextBox } from './index';

export interface DebouncedTextboxProps extends CarbonTextBoxProps {
  defaultValue?: string;
  onValueChangeDebounced?: (value: string) => void;
  validate?: FieldValidationHandler;
  debounceDelay?: number;
}

export type FieldValidationHandler = (value: string, isDirty: boolean) => string | undefined;

function DebouncedTextbox({ onValueChangeDebounced, onChange, defaultValue, debounceDelay = 500, ...props }: DebouncedTextboxProps) {
  const [isDirty, setDirty] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const textBoxRef = useRef<HTMLInputElement | null>();

  useEffect(() => {
    if (defaultValue && defaultValue !== lastDebouncedValue.current) {
      lastDebouncedValue.current = defaultValue;
      if (textBoxRef.current) {
        textBoxRef.current.value = defaultValue;
      }
    }
  }, [defaultValue]);

  const lastDebouncedValue = useRef<string | undefined>();
  const debounced = useDebounceCallback((value: string) => {
    onValueChangeDebounced?.(value);
    lastDebouncedValue.current = value;
  }, debounceDelay);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorMsg = props.validate?.(textBoxRef.current?.value ?? '', isDirty);

    if (errorMsg && error !== errorMsg) {
      setError(errorMsg);
      debounced.cancel();
      return;
    }

    if (error) {
      setError(undefined);
    }

    debounced(event.target.value);
    onChange?.(event);
  };

  return (
    <SharedTextBox
      {...props}
      ref={(ref) => {
        textBoxRef.current = ref;
      }}
      error={error}
      onFocus={() => !isDirty && setDirty(true)}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  );
}

export default DebouncedTextbox;
