import { useLingui } from '@lingui/react/macro';
import {
  buildOptimisticTimeClock,
  buildOptimisticTimeClockFromDimensionValue,
  PostApiTimeClocksIdDimensionValueRequestBody,
  TimeClock,
  usePostTimeClocks,
} from '@sit/client-shared';
import { useQueryClient } from '@tanstack/react-query';
import { useGetUser } from '@web/api/users/use-get-user';
import { UpdateClockPayload } from '@web/components/TimeClock/ActiveClock/ClockField';
import type { ClockActions } from '@web/components/TimeClock/types';
import TimeClockModal from '@web/components/TimeClock/ViewTimeClock/TimeClockModal';
import { useCurrentEntity } from '@web/hooks/use-current-entity';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { staffTimeClocksRoute } from './helpers/routes';
import { computeDisableDimensions, computeFallbackTimeclock, getPostTimeClockRequestBody } from './helpers/time-clocks';
import { useClockVisibleFields } from './hooks/useClockVisibleFields';

export const ErrorContainer = styled.div`
  padding-top: ${(props) => props.theme.space[3]};
  flex: 1;
  height: 540px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.space[3]};
`;

function useCreateTimeClockModal() {
  const { t } = useLingui();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const companyId = useCurrentEntity()?.id;
  const dimensionFields = useClockVisibleFields();
  const { mutateAsync: createTimeClock } = usePostTimeClocks({ replaceActive: false });
  const { data: user } = useGetUser();

  if (!companyId) {
    throw new Error(t`Company ID not found`);
  }

  if (!user) {
    throw new Error(t`User not found`);
  }

  const initial = useMemo(() => computeFallbackTimeclock(companyId, user), [companyId, user]);
  const disabledFallback = useMemo(() => computeDisableDimensions(user), [user]);

  const [draftClock, setDraftClock] = useState<TimeClock>(initial);
  const [activeDisableDimensions, setActiveDisableDimensions] = useState<string[]>(disabledFallback);

  const clearDraftClock = () => {
    setDraftClock(initial);
    setActiveDisableDimensions(disabledFallback);
  };

  const handleUpdateClock = (clock: TimeClock, body: UpdateClockPayload): Promise<void> => {
    const [optimisticClock, optimisticDisable] = buildOptimisticTimeClock(queryClient, clock, body);

    if (optimisticClock) {
      setDraftClock(optimisticClock);
      setActiveDisableDimensions(optimisticDisable);
    }

    return Promise.resolve();
  };

  const handleUpdateClockField = (clock: TimeClock, body: PostApiTimeClocksIdDimensionValueRequestBody) => {
    const [optimisticClock, optimisticDisable] = buildOptimisticTimeClockFromDimensionValue(queryClient, clock, body);

    if (optimisticClock) {
      setDraftClock(optimisticClock);
      setActiveDisableDimensions(optimisticDisable);
    }
  };

  const handleClockAction = async (action: ClockActions, target: TimeClock) => {
    if (action !== 'create') {
      return;
    }

    await createTimeClock({
      body: getPostTimeClockRequestBody(target),
      queryParams: {
        companyId: target.companyId,
      },
    });
    navigate(`${staffTimeClocksRoute}/${target.id}`, { replace: true });
  };

  const handleHideModal = () => {
    const hasHistory: boolean = window.history.state && window.history.state.idx > 0;
    if (hasHistory) {
      window.history.back();
    } else {
      navigate(`${staffTimeClocksRoute}`, { replace: true });
    }
  };

  return {
    draftClock,
    clearDraftClock,
    handleHideModal,
    dimensionFields,
    handleUpdateClock,
    handleClockAction,
    handleUpdateClockField,
    disabledDimensions: activeDisableDimensions,
  };
}

function CreateTimeClock() {
  const { draftClock, handleUpdateClock, handleUpdateClockField, handleClockAction, handleHideModal, dimensionFields, disabledDimensions } =
    useCreateTimeClockModal();

  return (
    <TimeClockModal
      isStaff
      isDraft
      clock={draftClock}
      onClose={handleHideModal}
      onAction={handleClockAction}
      onFieldChange={async (payload) => await handleUpdateClock(draftClock, payload)}
      onDimensionValueChange={(payload) => handleUpdateClockField(draftClock, payload)}
      dimensionFields={dimensionFields}
      disabledDimensions={disabledDimensions}
    />
  );
}

export default CreateTimeClock;
