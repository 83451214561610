import { Trans } from '@lingui/react/macro';
import { BuildType, setBuildType } from '@web/redux/Development';
import { store, useAppDispatch } from '@web/redux/store';
import { ThemeProvider } from '@web/styles/theme';
import Icon from 'carbon-react/lib/components/icon';
import CarbonMessage from 'carbon-react/lib/components/message';
import Typography from 'carbon-react/lib/components/typography';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[3]};
  height: 100vh;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[1]};
`;

const Title = styled(Typography)``;
const Message = styled(Typography)``;
const WarningIcon = styled(Icon)``;

const CookiesRequired = () => {
  const [isEmbedded, setIsEmbedded] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currentPath = window.location.pathname.substring(1);
    const embeddedEntryPoints = ['external-auth'];
    const isEmbeddedMode = embeddedEntryPoints.includes(currentPath);

    dispatch(setBuildType(isEmbeddedMode ? BuildType.embedded : BuildType.default));

    setIsEmbedded(isEmbeddedMode);
  }, [dispatch]);

  return (
    <Root>
      <WarningIcon type="warning" fontSize="extra-large" />
      <TextWrapper>
        <Title variant="h3">
          <Trans>Unable to load the page.</Trans>
        </Title>
        <Message variant="big">
          <Trans>Sage Intelligent Time requires browser cookies to load.</Trans>
        </Message>
        <CarbonMessage mt={3} showCloseIcon={false} open>
          <Trans>
            In your browser's settings, add <strong>{isEmbedded ? 'intacct.com' : window.location.hostname}</strong> to the list of sites
            allowed to use third-party cookies.
          </Trans>
        </CarbonMessage>
      </TextWrapper>
    </Root>
  );
};

export const CookiesRequirements = () => {
  // Initialize the necesary UI providers.
  return (
    <Provider store={store}>
      <ThemeProvider>
        <CookiesRequired />
      </ThemeProvider>
    </Provider>
  );
};
