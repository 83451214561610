import { useLingui } from '@lingui/react/macro';
import PublicPassword from '@web/components/Shared/Public/PublicPassword';
import Form from 'carbon-react/lib/components/form';
import Message from 'carbon-react/lib/components/message';
import { useFormik } from 'formik';
import PublicButton from '../../components/Shared/Public/PublicButton';
import PublicForm from '../../components/Shared/Public/PublicForm';

export interface ResetPasswordFormValues {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordFormProps {
  initialValues?: ResetPasswordFormValues;
  onSubmit: (values: ResetPasswordFormValues) => void;
  disabled?: boolean;
  errorMsg?: string;
  passwordOnly?: boolean;
}

const INITIAL_VALUES: ResetPasswordFormValues = {
  password: '',
  passwordConfirm: '',
};

const useFormValidation = () => {
  const { t } = useLingui();
  const validate = (values: ResetPasswordFormValues) => {
    const errors: Partial<ResetPasswordFormValues> = {};

    if (!values.password) {
      errors.password = t`Required`;
    } else if (values.password.length < 8) {
      errors.password = t`Must be at least 8 characters`;
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = t`Required`;
    } else if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = t`Passwords don't match`;
    }

    return errors;
  };

  return validate;
};

const ResetPasswordForm = ({ errorMsg, disabled, onSubmit, initialValues = INITIAL_VALUES }: ResetPasswordFormProps) => {
  const { t } = useLingui();
  const validate = useFormValidation();
  const { handleSubmit, values, errors, handleChange } = useFormik<ResetPasswordFormValues>({
    initialValues,
    validate,
    isInitialValid: false,
    validateOnChange: false,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { password, passwordConfirm } = values;

  return (
    <PublicForm title={t`Reset your password`}>
      <Form
        saveButton={<PublicButton buttonType="primary" disabled={disabled} key="register" type="submit" label={t`Save`} />}
        onSubmit={handleSubmit}
      >
        <PublicPassword
          label={t`New password`}
          labelInline={false}
          disabled={disabled}
          labelAlign="left"
          name="password"
          onChange={handleChange}
          value={password}
          error={errors.password}
        />
        <PublicPassword
          label={t`Confirm new password`}
          labelInline={false}
          disabled={disabled}
          labelAlign="left"
          name="passwordConfirm"
          onChange={handleChange}
          value={passwordConfirm}
          error={errors.passwordConfirm}
        />
        {errorMsg && (
          <div className="error-wrapper">
            <Message variant="error">{errorMsg}</Message>
          </div>
        )}
      </Form>
    </PublicForm>
  );
};

export default ResetPasswordForm;
