import { useLingui } from '@lingui/react/macro';
import { usePostApiAuthMfaFallback } from '@sit/client-shared';
import { usePostLogin } from '@web/api/auth/use-post-user-login';
import JoinUsDialog from '@web/components/JoinUsDialog';
import toastr from '@web/helpers/toastr';
import { useFlag } from '@web/hooks';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import PublicPage from '../../components/Shared/Public/PublicPage';
import LoginForm, { LoginFormValues } from './Login';
import MfaForm, { MfaFormValues } from './Mfa';
import { MfaTypes } from './MfaTypes';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type LoginStep = 'LOGIN' | 'MFA' | 'EMAIL_MFA';

const Login = () => {
  const { t } = useLingui();
  const { mutate: login, isLoading: loginIsLoading, error: loginError } = usePostLogin();
  const [formData, setFormData] = useState<LoginFormValues>({
    company: '',
    username: '',
    password: '',
  });
  const { mutate: sendFallbackMfaCodeRequest, error: mfaFallbackError, isLoading: mfaIsLoading } = usePostApiAuthMfaFallback();
  // Assume enabled on initial load before flag is fetched
  const pageDisabled = !(useFlag('ENABLE_PASSWORD_LOGIN') ?? true);
  const [step, setStep] = useState<LoginStep>('LOGIN');

  const handleSubmit = (data: LoginFormValues) => {
    setFormData(data);
    login(
      {
        body: {
          ...data,
          timezone,
        },
        requestConfig: {
          skipToast: true,
        },
      },
      {
        onError: (error) => {
          if (error?.message?.includes('Two-Factor authentication is required')) {
            setStep('MFA');
          }
        },
      },
    );
  };

  const handlePhoneMfaSubmit = ({ code }: MfaFormValues) => {
    login({
      body: {
        ...formData,
        code,
        timezone,
      },
      requestConfig: {
        skipToast: true,
      },
    });
  };

  const handleEmailMfaSubmit = ({ code: fallbackToken }: MfaFormValues) => {
    login({
      body: {
        ...formData,
        fallbackToken,
        timezone,
      },
      requestConfig: {
        skipToast: true,
      },
    });
  };

  const resendPhoneCode = () => {
    handleSubmit(formData);
  };

  const resendEmailCode = () => {
    sendFallbackMfaCodeRequest(
      {
        body: formData,
      },
      {
        onSuccess: () => {
          toastr.success(t`Sent code to email`);
        },
      },
    );
  };

  const switchMethod = (type: MfaTypes) => {
    switch (type) {
      case MfaTypes.Email:
        resendEmailCode();
        return setStep('EMAIL_MFA');

      default:
        resendPhoneCode();
        return setStep('MFA');
    }
  };

  const errorMessage = mfaFallbackError?.message ?? loginError?.message;
  const loading = loginIsLoading || mfaIsLoading;

  return (
    <>
      <Helmet>
        <title>{t`Login | Sage Intelligent Time`}</title>
      </Helmet>
      <PublicPage>
        <>
          <JoinUsDialog closeable={!pageDisabled} />
          {step === 'LOGIN' && <LoginForm disabled={pageDisabled} onSubmit={handleSubmit} errorMsg={errorMessage} loading={loading} />}
          {step === 'MFA' && (
            <MfaForm
              type={MfaTypes.Phone}
              loading={loading}
              disabled={pageDisabled}
              resend={resendPhoneCode}
              requestSwitch={switchMethod}
              onSubmit={handlePhoneMfaSubmit}
              errorMsg={errorMessage}
            />
          )}
          {step === 'EMAIL_MFA' && (
            <MfaForm
              disableTypeSwitch
              type={MfaTypes.Email}
              disabled={pageDisabled}
              errorMsg={errorMessage}
              loading={loading}
              resend={resendEmailCode}
              onSubmit={handleEmailMfaSubmit}
            />
          )}
        </>
      </PublicPage>
    </>
  );
};

export default Login;
