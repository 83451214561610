import { Trans } from '@lingui/react/macro';
import Typography from 'carbon-react/lib/components/typography/typography.component';
import { Link as RDomLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
// eslint-disable-next-line import-x/no-unresolved -- todo, figure out how to satisfy eslint
import SageLogo from '../../assets/img/Logo.svg?react';

const Root = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.black};
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.palette.blackTint(37)};
  border-radius: 5px;
  height: 25px;
  width: 1px;
`;

const ProductName = styled(Typography)``;

const LinkStyle = css`
  cursor: pointer;
  padding: 0 20px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const AppLink = styled(RDomLink)`
  ${LinkStyle}
`;

const ExternalLink = styled.a`
  ${LinkStyle}
`;

export const DashboardFooter = () => {
  return (
    <Root>
      <Section>
        <AppLink to="/privacy">
          <Trans>Privacy</Trans>
        </AppLink>
        <ExternalLink
          href="https://public-assets-dev-c8ha462swai.s3-us-west-1.amazonaws.com/licenses/web/third-party-licenses.html"
          target="_blank"
          rel="noreferrer"
        >
          <Trans>3rd party licenses</Trans>
        </ExternalLink>
      </Section>
      <Section>
        <SageLogo />
        <Divider />
        <ProductName mb={0}>
          <Trans>Intelligent Time</Trans>
        </ProductName>
      </Section>
    </Root>
  );
};
