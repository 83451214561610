import { useLingui } from '@lingui/react/macro';
import {
  SummaryDateItem,
  SummaryDurationItem,
  SummaryItem,
  SummaryPillStatusItem,
  SummaryPreviewItem,
} from '@web/components/Timesheet/SummaryCards';
import { parse } from 'date-fns';

const makeToSummaryItem =
  <T extends SummaryItem>(typename: T['__typename']) =>
  (label: T['label'], value: T['value'], stick = false, testId?: string): T =>
    ({
      __typename: typename,
      label,
      value,
      stick,
      testId,
    }) as T;

const toDateSummaryItem = makeToSummaryItem<SummaryDateItem>('date');
const toDurationSummaryItem = makeToSummaryItem<SummaryDurationItem>('duration');
const toPillStatusSummaryItem = makeToSummaryItem<SummaryPillStatusItem>('pill-status');
const toPreviewItem = makeToSummaryItem<SummaryPreviewItem>('preview');

interface UseSummaryDataProps {
  startDate: string;
  endDate: string;
  glPostDate: string | null;
  status: string;
  showBillable: boolean;
  total: number;
  billable: number;
  nonBillable: number;
}

export const useSummaryData = (props: UseSummaryDataProps): SummaryItem[] => {
  const { t } = useLingui();

  const { startDate, endDate, glPostDate, status, showBillable, total, billable, nonBillable } = props;

  return [
    ...(startDate ? [toDateSummaryItem(t`Begin date`, parse(startDate, 'yyyy-MM-dd', new Date()))] : [toPreviewItem(t`Begin date`)]),
    ...(endDate ? [toDateSummaryItem(t`End date`, parse(endDate, 'yyyy-MM-dd', new Date()))] : [toPreviewItem(t`End date`)]),
    toDurationSummaryItem(t`Total hours`, total, undefined, 'total-hours'),
    ...(showBillable
      ? [
          toDurationSummaryItem(t`Billable hours`, billable, undefined, 'total-billable'),
          toDurationSummaryItem(t`Non-billable hours`, nonBillable, undefined, 'total-non-billable'),
        ]
      : []),
    ...(glPostDate ? [toDateSummaryItem(t`GL post date`, parse(glPostDate, 'yyyy-MM-dd', new Date()))] : [toPreviewItem(t`GL post date`)]),
    toPillStatusSummaryItem(t`Timesheet status`, status),
  ] as const;
};
