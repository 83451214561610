import { Trans } from '@lingui/react/macro';
import { useHasStaffTimesheets } from '@sit/client-shared';
import { entriesApprovalsRoute } from '@web/containers/EntriesApprovals/helpers/routes';
import { timeApprovalsRoute } from '@web/containers/TimeApprovals/helpers/routes';
import { timeClocksRoute } from '@web/containers/TimeClocks/helpers/routes';
import { useClockInOutEnabled } from '@web/hooks/use-clock-in-out';
import { Menu, MenuItem } from 'carbon-react/lib/components/menu';
import styled from 'styled-components';
import { DebugVersion } from '../DebugVersion';
import { useIsActiveTab } from './hooks/useIsActiveTab';

export type SecondaryNavTabs =
  | 'timesheets'
  | 'staff-timesheets'
  | 'approvals'
  | 'data-sources'
  | 'time-approvals'
  | 'entries-approvals'
  | 'time-clocks'
  | 'staff-time-clocks';

interface SecondaryNavProps {
  onTabChange: (tab: SecondaryNavTabs) => void;
  hideMyTimesheetsTab: boolean;
  hideApprovalsTab: boolean;
}

interface ApprovalsNavigationProps {
  hideApprovalsTab?: boolean;
  clickHandler: (tab: SecondaryNavTabs) => (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
}

interface TimeClockNavigationProps {
  clickHandler: (tab: SecondaryNavTabs) => (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
}

const ApprovalsNavigation = ({ hideApprovalsTab, clickHandler }: ApprovalsNavigationProps) => {
  const { isActive } = useIsActiveTab();
  if (hideApprovalsTab) {
    return null;
  }
  return (
    <>
      <MenuItem
        data-pendo-id="time-approvals-tab"
        href={timeApprovalsRoute}
        selected={isActive('time-approvals')}
        onClick={clickHandler('time-approvals')}
      >
        <Trans>Approve timesheets</Trans>
      </MenuItem>
      <MenuItem
        data-pendo-id="entries-approvals-tab"
        href={entriesApprovalsRoute}
        selected={isActive('entries-approval')}
        onClick={clickHandler('entries-approvals')}
      >
        <Trans>Approve time entries</Trans>
      </MenuItem>
    </>
  );
};

const TimeClockNavigation = ({ clickHandler }: TimeClockNavigationProps) => {
  const { isActive } = useIsActiveTab();
  const isStaff = useHasStaffTimesheets();
  const enabled = useClockInOutEnabled();

  if (!enabled) {
    return null;
  }

  return (
    <>
      <MenuItem
        data-pendo-id="time-clocks-tab"
        href={timeClocksRoute}
        selected={isActive('time-clocks')}
        onClick={clickHandler('time-clocks')}
      >
        <Trans>My time clocks</Trans>
      </MenuItem>
      {isStaff && (
        <MenuItem
          data-pendo-id="time-clocks-tab"
          href={timeClocksRoute}
          selected={isActive('staff-time-clocks')}
          onClick={clickHandler('staff-time-clocks')}
        >
          <Trans>Staff time clocks</Trans>
        </MenuItem>
      )}
    </>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.palette.slateTint(90)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 40px;
  padding: 0 ${({ theme }) => theme.spacing * 2}px;
  z-index: ${({ theme }) => theme.zIndex.aboveAll + 1};

  @media screen and (min-width: 1280px) {
    padding: 0 ${({ theme }) => theme.spacing * 13}px;
  }
`;

const SecondaryNav = ({ onTabChange, hideMyTimesheetsTab, hideApprovalsTab }: SecondaryNavProps) => {
  const { isActive } = useIsActiveTab();

  function createOnClick(tab: SecondaryNavTabs) {
    return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onTabChange(tab);
    };
  }

  const hasStaffTimesheets = useHasStaffTimesheets();

  return (
    <Root>
      <Menu menuType="light">
        {!hideMyTimesheetsTab && (
          <MenuItem
            data-pendo-id="timesheets-tab"
            href="/timesheets"
            selected={isActive('timesheets')}
            onClick={createOnClick('timesheets')}
          >
            <Trans>My timesheets</Trans>
          </MenuItem>
        )}
        {hasStaffTimesheets && (
          <MenuItem
            data-pendo-id="staff-timesheets-tab"
            href="/staff-timesheets"
            selected={isActive('staff-timesheets')}
            onClick={createOnClick('staff-timesheets')}
          >
            <Trans>Staff timesheets</Trans>
          </MenuItem>
        )}
        <ApprovalsNavigation clickHandler={createOnClick} hideApprovalsTab={hideApprovalsTab} />
        <TimeClockNavigation clickHandler={createOnClick} />
        <MenuItem
          data-pendo-id="data-sources-tab"
          href="/data-sources"
          selected={isActive('data-sources')}
          onClick={createOnClick('data-sources')}
        >
          <Trans>Time sources</Trans>
        </MenuItem>
      </Menu>
      <DebugVersion />
    </Root>
  );
};

export default SecondaryNav;
