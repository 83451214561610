// eslint-disable-next-line import-x/default -- this is actually probably a bug
import tokens from '@sage/design-tokens/js/base/common';
import generatePalette from 'carbon-react/lib/style/palette';
import baseTheme, { mergeWithBase } from 'carbon-react/lib/style/themes/base';
import { BasePalette, Colors, ThemeObject } from 'carbon-react/lib/style/themes/base/base-theme.config';
import sageThemeDefinitions from 'carbon-react/lib/style/themes/sage';
import { SitColors, SitPalette, baseColors, buildPillColors } from './colors';

export interface SitTheme extends Omit<ThemeObject, 'colors' | 'palette'> {
  name: string;
  spacing: number;
  space: string[];
  colors: SitColors;
  palette: SitPalette;
  disabled: {
    background: string;
  };
  zIndex: {
    smallOverlay: number;
    overlay: number;
    popover: number;
    nav: number;
    globalNav: number;
    modal: number;
    header: number;
    fullScreenModal: number;
    notification: number;
    aboveAll: number;
  };
  compatibility: Record<string, string | undefined>;
}

const buildPalette = (colors: Record<string, string>) => {
  return {
    ...colors,
    ...generatePalette(colors),
  };
};

const buildSitPalette = (basePalette: BasePalette, colors: Record<string, string>): SitPalette => {
  return {
    ...basePalette,
    ...buildPalette(colors),
  } as SitPalette;
};

const buildCompatibility = (baseCompatibility: Record<string, string | undefined>, metaName?: string) => ({
  ...baseCompatibility,
  metaName,
});

const buildExtendedBaseTheme = (colorsDefinition: Colors) => {
  const {
    colors: partialColors,
    palette,
    ...theme
  } = {
    ...baseTheme,
    ...mergeWithBase(() => {
      return {
        colors: {
          ...colorsDefinition,
        },
      };
    }),
  };

  return {
    ...theme,
    palette: palette as BasePalette & Pick<SitPalette, 'colors'>,
    colors: {
      ...colorsDefinition,
      ...partialColors,
    },
  };
};

const buildSitTheme = (themeName: string, partialColors: Partial<Colors> = {}): SitTheme => {
  const name = `SIT-${themeName}`;
  const colorsDefinition = { ...baseColors, ...partialColors };
  const { colors: themeColors, palette, ...themeDefinition } = buildExtendedBaseTheme(colorsDefinition);

  const pillColors = buildPillColors(colorsDefinition, palette);
  const { primary, secondary, tertiary } = colorsDefinition;

  const colors = {
    ...themeColors,
    ...colorsDefinition,
  };

  return {
    ...themeDefinition,
    name,
    palette: buildSitPalette(palette, { primary, secondary, tertiary }),
    compatibility: buildCompatibility(tokens, name),
    colors: {
      ...colors,
      ...pillColors,
    },
  };
};

export const defaultTheme = buildSitTheme('Default', sageThemeDefinitions.colors);
export const embedTheme = buildSitTheme('Embeded', {
  ...sageThemeDefinitions.colors,
});
