import { formatDimensionValue } from '@sit/core';
import { OptionProps } from 'carbon-react/lib/components/select/option';

export interface MapOptions {
  showExternalId?: boolean;
}

export const mapOptionWithExternalId: <T extends Partial<{ name: string | null; externalId: string | null; id: string }>>(
  data: T,
  config: MapOptions,
) => OptionProps = ({ name, externalId, id: value }, config) => ({
  text: formatDimensionValue(config.showExternalId ?? false, externalId ?? '', name ?? ''),
  value: value ?? '',
});
