import { useLingui } from '@lingui/react/macro';
import {
  AccountCalendar,
  PutApiCalendarCalendarsIdRequestBody,
  UsePutApiCalendarOptimisticContext,
  usePutApiCalendarOptimistic,
} from '@sit/client-shared';
import Tooltip from '@web/components/CustomTooltip';
import Button from '@web/components/Shared/Button';
import StatusPill from '@web/components/StatusPill';
import { PillTextType, StatusPillColors } from '@web/components/StatusPill/hooks/usePillLocales';
import { FlatTableCell, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import Loader from 'carbon-react/lib/components/loader';
import Typography from 'carbon-react/lib/components/typography';
import styled from 'styled-components';

const ProviderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 600;
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 15px;
  text-align: center;
  width: 33px;
`;

const ProviderLogo = styled.img`
  width: 33px;
`;

const CalendarName = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
`;

const AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const StatusMessage = styled.div`
  font-size: 14px;
`;

const ProviderMessageWrapper = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  min-width: 400px;
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  width: 420px;
`;

const ProviderMessage = styled(Typography)`
  font-size: 14px;
`;

interface CalendarProps {
  calendar: AccountCalendar;
}

const useCalendar = ({ id, type, active }: AccountCalendar) => {
  const { t } = useLingui();
  const { mutate: updateCalendar, context: updateCalendarContext, isLoading } = usePutApiCalendarOptimistic();

  const isCalendarLoading = isLoading && (updateCalendarContext as UsePutApiCalendarOptimisticContext)?.calendarId === `${id}`;

  const updateCalendarAccount = (body: PutApiCalendarCalendarsIdRequestBody) =>
    updateCalendar({
      pathParams: {
        id: `${id}`,
      },
      body,
    });

  const handleUpdate = () => {
    updateCalendarAccount({ active: !active });
  };

  return {
    isLoading: isCalendarLoading,
    handleUpdate,
    isDestructive: active,
    pillText: (active ? 'Active' : 'Inactive') as PillTextType,
    pillType: (active ? 'success' : 'default') as StatusPillColors,
    buttonLabel: active ? t`Delete` : t`Connect`,
    message: active ? t`This calendar is connected.` : t`This calendar is not connected.`,
    providerLogoUrl: `${import.meta.env.VITE_APP_S3_URL}/${type === 'MICROSOFT' ? 'microsoft-outlook.png' : 'google-calendar.png'}`,
  };
};

const Calendar = ({ calendar }: CalendarProps) => {
  const { pillText, pillType, message, handleUpdate, buttonLabel, providerLogoUrl, isDestructive, isLoading } = useCalendar(calendar);

  return (
    <FlatTableRow key={calendar.id}>
      <FlatTableCell>
        <ProviderWrapper style={{ marginLeft: 20, position: 'relative' }}>
          <ImageWrapper>
            <ProviderLogo alt="calendar" height="33px" src={providerLogoUrl} />
          </ImageWrapper>
          <Tooltip overlay={calendar.name}>
            <CalendarName>{calendar.name}</CalendarName>
          </Tooltip>
        </ProviderWrapper>
      </FlatTableCell>
      <FlatTableCell>
        <AccountName>{calendar.providerUserId}</AccountName>
      </FlatTableCell>
      <FlatTableCell>
        <StatusMessage>
          <StatusPill text={pillText} type={pillType} />
        </StatusMessage>
      </FlatTableCell>
      <FlatTableCell>
        <ProviderMessageWrapper>
          <Tooltip overlay={message}>
            <ProviderMessage mb={0}>{message}</ProviderMessage>
          </Tooltip>
        </ProviderMessageWrapper>
      </FlatTableCell>
      <FlatTableCell>
        <div>
          <Button onClick={handleUpdate} size="small" destructive={isDestructive} fullWidth>
            {isLoading ? <Loader isInsideButton /> : buttonLabel}
          </Button>
        </div>
      </FlatTableCell>
      <FlatTableCell />
    </FlatTableRow>
  );
};

export default Calendar;
