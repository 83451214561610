import { TimeClock } from '@sit/client-shared';

export enum ClockState {
  clockedIn = 'clocked-in',
  clockedOut = 'clocked-out',
  onBreak = 'on-break',
}

export function getClockState(clock: TimeClock): ClockState {
  const { breaks, clockOutTime } = clock;

  const onBreak = breaks.some((b) => !b.endTime);

  if (clockOutTime) {
    return ClockState.clockedOut;
  }

  if (onBreak) {
    return ClockState.onBreak;
  }

  return ClockState.clockedIn;
}

function resolvedClockState(clockOrState: TimeClock | ClockState): ClockState {
  return typeof clockOrState === 'object' ? getClockState(clockOrState) : clockOrState;
}

export function isClockRunning(clockOrState: TimeClock | ClockState): boolean {
  const state = resolvedClockState(clockOrState);

  return [ClockState.clockedIn, ClockState.onBreak].includes(state);
}

export function isClockOnBreak(clockOrState: TimeClock | ClockState): boolean {
  const state = resolvedClockState(clockOrState);

  return state === ClockState.onBreak;
}
