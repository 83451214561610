import { PushNotification } from '@sit/client-shared';

export type NotificationHandler = (notification: PushNotification) => void;

export type NotificationType = 'SUCCESS' | 'INFO' | 'ERROR' | 'WARNING' | 'PROMOTION';

export enum NotificationTopic {
  DATA_SOURCES = 'DATA_SOURCES',
  SAVE_TIMESHEET = 'SAVE_TIMESHEET',
  SUBMIT_TIMESHEET = 'SUBMIT_TIMESHEET',
  APPROVALS = 'APPROVALS',
  TIMESHEETS = 'TIMESHEETS',
}

class NotificationHandlerService {
  private handlers = new Map<string, NotificationHandler[]>();

  public addHandler(topic: NotificationTopic, handler: NotificationHandler) {
    const notificationHandlers = this.handlers.get(topic) || [];

    if (notificationHandlers.includes(handler)) {
      return () => undefined;
    }
    notificationHandlers.push(handler);
    this.handlers.set(topic, notificationHandlers);

    return () => {
      this.removeHandler(topic, handler);
    };
  }

  public removeHandler(topic: NotificationTopic, handler: NotificationHandler): void {
    const notificationHandlers = this.handlers.get(topic);
    if (notificationHandlers) {
      const index = notificationHandlers.indexOf(handler);
      if (index !== -1) {
        notificationHandlers.splice(index, 1);
      }
    }
  }

  public handleNotification(notification: PushNotification): void {
    const { topic } = notification;
    const notificationHandlers = this.handlers.get(topic) || [];
    for (const handler of notificationHandlers) {
      handler(notification);
    }
  }

  public hasHandlers(topic: NotificationTopic): boolean {
    const notificationHandlers = this.handlers.get(topic);

    return !!notificationHandlers && notificationHandlers.length > 0;
  }
}

export default NotificationHandlerService;
