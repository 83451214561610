import { useLingui } from '@lingui/react/macro';
import { loadingChunkRetry } from '@sit/client-shared';
import ErrorBoundary from '@web/components/ErrorBoundary';
import { PageContent, PageHeader } from '@web/components/Page';
import DashboardContainer from '@web/containers/DashboardContainer';
import { NotFoundMessage } from '@web/containers/NotFoundPage/NotFoundMessage';
import { ErrorComponent } from '@web/containers/TimeApprovals/Error';
import { useClockInOutEnabled } from '@web/hooks/use-clock-in-out';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

const TimeClocksPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeClocks')));
const TimeClockDetailsPage = lazy(() => loadingChunkRetry(() => import('@web/containers/TimeClocks/TimeClockDetails')));

const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 58px);
  flex-direction: column;
  div[data-component='tabs'] {
    width: 100%;
  }
`;

const TimeClocksIndexLayout = () => {
  const { t } = useLingui();

  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{t`My time clocks | Sage Intelligent Time`}</title>
      </Helmet>

      <PageContent>
        <PageHeader data-testid="time-clocks-title" title={t`My time clocks`} />
        <ErrorBoundary fallbackUI={(error) => <ErrorComponent error={error} />}>
          <Suspense fallback={null}>
            <Content>
              <Outlet />
            </Content>
          </Suspense>
        </ErrorBoundary>
      </PageContent>
    </DashboardContainer>
  );
};

function TimeClocksIndex() {
  const enabled = useClockInOutEnabled();

  if (!enabled) {
    return (
      <Routes>
        <Route path="*" element={<NotFoundMessage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<TimeClocksIndexLayout />}>
        <Route index element={<TimeClocksPage />} />
        <Route path=":timeClockId/*" element={<TimeClocksPage activeModal={<TimeClockDetailsPage />} />} />
      </Route>

      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  );
}

export default TimeClocksIndex;
