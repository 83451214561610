import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import SITLogo from '../../../assets/img/sit_logo.png';
import { NotificationData } from '../notification-data';
import { NotificationDetails } from './NotificationDetails';
const Root = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.slateTint(60)};
  margin: 0 ${({ theme }) => theme.spacing / 2}px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.slateTint(90)};
  }

  &:focus {
    outline: solid 3px ${({ theme }) => theme.palette.gold};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const NotificationBody = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

const NotificationIconWrapper = styled.div`
  height: 100%;
  min-height: 88px;
  width: 88px;
`;

const Icon = styled.img`
  height: 48px;
  margin: 20px;
  width: 48px;
`;

interface DefaultNotificationProps extends HTMLAttributes<HTMLButtonElement> {
  notification: Pick<NotificationData, 'notification' | 'timestamp'>;
  icon?: string;
}

const Notification = ({ notification, icon = SITLogo, ...props }: DefaultNotificationProps): JSX.Element => {
  return (
    <Root {...props}>
      <NotificationBody>
        <NotificationIconWrapper>
          <Icon alt="avatar" src={icon} />
        </NotificationIconWrapper>
        <NotificationDetails notification={notification.notification} timestamp={notification.timestamp} />
      </NotificationBody>
    </Root>
  );
};

export default Notification;
