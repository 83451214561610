import { useNavigate } from 'react-router-dom';
import { NotificationData } from './notification-data';
import DefaultNotification from './notification/DefaultNotification';
import DescriptionNotification from './notification/DescriptionNotification';
import { NotificationTopic } from './NotificationHandlerService';

interface NotificationProps {
  notification: NotificationData;
}

const NotificationTile = ({ notification }: NotificationProps): JSX.Element | null => {
  const navigate = useNavigate();

  const { meta, metaId } = notification;
  const isTimesheetNotification =
    !!meta && [NotificationTopic.TIMESHEETS, NotificationTopic.SUBMIT_TIMESHEET, NotificationTopic.SAVE_TIMESHEET].includes(meta);
  const handleClick = () => {
    if (isTimesheetNotification && metaId) {
      return navigate(`/timesheet/${metaId}`);
    }

    if (meta === NotificationTopic.TIMESHEETS) {
      return navigate('/timesheets');
    }

    if (meta === NotificationTopic.DATA_SOURCES) {
      return navigate('/data-sources');
    }
  };

  if (isTimesheetNotification) {
    return <DescriptionNotification notification={notification} onClick={handleClick} />;
  }

  return <DefaultNotification notification={notification} onClick={handleClick} />;
};

export default NotificationTile;
