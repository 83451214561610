import { useIntacctCompanyId } from '@sit/client-shared';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { useGetUser } from '@web/api/users/use-get-user';
import { useEffect } from 'react';
import { useAppSelector } from '../redux/store';
import { selectBuildType } from './../redux/selectors/index';
import { initialize } from './analyticsClient';

function useCompanyPendoData() {
  const { data: company } = useGetCurrentCompany();
  const {
    allowNegativeHours = null,
    id = '',
    intacctCompanyId = '',
    mfaRequired = null,
    name = '',
    tenantType = null,
    timesheetDuration = null,
    workdayHours = '',
  } = company ?? {};
  return { allowNegativeHours, id, intacctCompanyId, mfaRequired, name, tenantType, timesheetDuration, workdayHours };
}

function useUserPendoData(isAuthenticated: boolean) {
  const { data: userData } = useGetUser({
    enabled: isAuthenticated,
  });
  const companyName =
    useIntacctCompanyId({
      enabled: isAuthenticated,
    }) ?? 'NO_COMPANY_NAME';
  if (!userData || !userData.companies || !userData.company) return null;
  const entityData = userData.companies.find((c) => c.id === userData.company.id);
  const companyEntityName = entityData?.parentId === null ? 'TOP LEVEL' : (entityData?.name ?? 'NO_ENTITY_NAME');
  const companyId = userData.company.id ?? 'NO_COMPANY_ID';

  const result: Record<string, string | number | boolean | string[]> = {
    email: userData.email,
    id: userData.id,
    twoFactorEnabled: userData.twoFactorEnabled,
    username: userData.username,
    companyName,
    companyId,
    companyEntityName,
    ...(userData.position ? { position: userData.position } : {}),
    ...(userData.name ? { full_name: userData.name } : {}),
  };
  if (userData.role) result.role = userData.role;
  return result;
}

export default function useIdentify(isAuthenticated: boolean) {
  const userDataForPendo = useUserPendoData(isAuthenticated);
  const companyDataForPendo = useCompanyPendoData();
  const buildType = useAppSelector(selectBuildType);

  useEffect(() => {
    if (userDataForPendo?.id && companyDataForPendo?.id) {
      // The .identify method of pendo doesn't allow to pass in extra data, so we use the .initialize instead
      const visitor = {
        ...userDataForPendo,
        buildType,
      };
      initialize({
        visitor,
        account: companyDataForPendo,
        excludeAllText: true,
      });
    }
  }, [userDataForPendo, companyDataForPendo, buildType]);
}
