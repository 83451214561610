import { useLingui } from '@lingui/react/macro';
import { PageContent, PageHeader } from '@web/components/Page';
import { NotFoundMessage } from '@web/containers/NotFoundPage/NotFoundMessage';
import { Tab, Tabs } from 'carbon-react/lib/components/tabs';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import TimesheetApprovals from './time-approvals.$approvalType';
import TimesheetApproval from './time-approvals.$id';

import ErrorBoundary from '@web/components/ErrorBoundary';
import DashboardContainer from '@web/containers/DashboardContainer';
import { useTimeApprovalTabs } from '@web/hooks/approvals/useApprovalTabs';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 58px);
  flex-direction: column;
  div[data-component='tabs'] {
    width: 100%;
  }
`;

const OutletContent = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  padding: ${({ theme }) => theme.spacing * 3}px;
  padding-top: ${({ theme }) => theme.spacing}px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
`;

const TimeApprovalsIndexLayout = () => {
  const { t } = useLingui();
  const { selectedTabId, handleTabChange } = useTimeApprovalTabs();

  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{t`Approve Timesheets | Sage Intelligent Time`}</title>
      </Helmet>

      <PageContent>
        <PageHeader data-cy="time-approvals-title" title={t`Approve timesheets`} />
        <Content>
          <Tabs onTabChange={handleTabChange} position="top" className="tabs" selectedTabId={selectedTabId}>
            <Tab tabId={'todo'} title={t`To do`} />
            <Tab tabId={'done'} title={t`Done`} />
          </Tabs>
          <OutletContent data-pendo-id="approvals-tab-content">
            <Outlet />
          </OutletContent>
        </Content>
      </PageContent>
    </DashboardContainer>
  );
};

const TimeApprovalsIndex = () => {
  return (
    <Routes>
      <Route element={<TimeApprovalsIndexLayout />}>
        <Route index element={<Navigate replace to="todo" />} />
        <Route
          path="todo"
          element={
            <ErrorBoundary>
              <TimesheetApprovals />
            </ErrorBoundary>
          }
        />
        <Route
          path="done"
          element={
            <ErrorBoundary>
              <TimesheetApprovals />
            </ErrorBoundary>
          }
        />
      </Route>

      <Route
        path=":id/*"
        element={
          <ErrorBoundary>
            <TimesheetApproval />
          </ErrorBoundary>
        }
      />

      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  );
};

export default TimeApprovalsIndex;
