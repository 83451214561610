import { t } from '@lingui/core/macro';
import {
  ErrorClassification,
  addAfterRetryErrorInterceptor,
  addBeforeRetryErrorInterceptor,
  initializeSessionStorage,
  setBaseUrl,
  type SessionStorageCallback,
  type SessionStorageCallbackWithResult,
} from '@sit/client-shared';
import { AxiosError } from 'axios';
import { config } from '../config/env';
import { updateNetworkStatus } from './network-status';
import toastr from './toastr';

setBaseUrl(config.apiUrl);

let authFailedAt: number | null = null;

addBeforeRetryErrorInterceptor((error) => {
  if (error.code === AxiosError.ERR_NETWORK) {
    if (navigator.onLine) {
      // server error, 500+
      toastr.error(t`Oops! We can't connect to the server right now. Please wait a few minutes before refreshing the page.`, {
        toastId: ErrorClassification.NETWORK_SERVER_DOWN,
      });
    } else {
      updateNetworkStatus(false);
    }
    throw error;
  }
  return Promise.resolve();
});

addAfterRetryErrorInterceptor((error) => {
  const isRefreshTokenUrl = error.config.url === '/pub/token';
  // We are at log out process if the request is to refresh token or the last auth failed within 1 second
  // The logout process won't take more than 1/5 second so the margin of 1 second is more than enough
  const isLoggingOut = isRefreshTokenUrl || (authFailedAt && Date.now() - authFailedAt < 1000);

  // If the user is logging out, we don't want to display a toast
  // We added navigator.onLine to prevent the toast from showing when the user is offline

  const skipToast =
    typeof error.config.skipToast === 'boolean'
      ? error.config.skipToast
      : typeof error.config.skipToast === 'function'
        ? error.config.skipToast(error)
        : false;

  const shouldDisplayToast = !isLoggingOut && !skipToast && navigator.onLine && error.code !== ErrorClassification.ERR_CANCELED;

  if (isRefreshTokenUrl) {
    // Register the last refresh token failed timestamp
    authFailedAt = Date.now();
  }

  if (error.message === ErrorClassification.UNCLASSIFIED) {
    if (error.status === 401) {
      error.setMessage(t`Unauthorized`);
    } else {
      error.setMessage(t`Something went wrong. Please try again.`);
    }
  }

  if (shouldDisplayToast) {
    toastr.error(error.message, { toastId: error.config.toastId });
  }
  return Promise.resolve();
});

initializeSessionStorage({
  getItem: (key: string, cb: SessionStorageCallbackWithResult<string> | undefined) => {
    const result = sessionStorage.getItem(key);
    cb?.(null, result);
    return result;
  },
  setItem: (key: string, value: string, cb: SessionStorageCallback | undefined) => {
    sessionStorage.setItem(key, value);
    cb?.(null);
  },
  removeItem: (key: string, cb: SessionStorageCallback | undefined) => {
    sessionStorage.removeItem(key);
    cb?.(null);
  },
  clear: (cb: SessionStorageCallback | undefined) => {
    sessionStorage.clear();
    cb?.(null);
  },
});
