import IconButton from 'carbon-react/lib/components/icon-button';
import { RenderOpenProps } from 'carbon-react/lib/components/popover-container';
import styled from 'styled-components';
// eslint-disable-next-line import-x/no-unresolved -- todo, figure out how to satisfy eslint
import NotificationBell from '../../assets/img/icons/notification-bell.svg?react';
import useNotifications from './hooks/useNotifications';

const StyledSvg = styled.div`
 svg {
    fill: white;
  }
`;

interface Props extends RenderOpenProps {}

const NotificationButton = ({ isOpen: _isOpen, ...props }: Props): JSX.Element => {
  const notifications = useNotifications();

  const hasUnreadNotifications = notifications.some((notification) => !notification.pushIsRead);

  return (
    <IconButton aria-label="Notifications" data-pendo-id="notifications" {...props}>
      <>
        <StyledSvg>
          <NotificationBell />
        </StyledSvg>
        {hasUnreadNotifications && <span className="notifications-unread-indicator" />}
      </>
    </IconButton>
  );
};

export default NotificationButton;
