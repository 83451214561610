import { Trans } from '@lingui/react/macro';
import Box from 'carbon-react/lib/components/box';
import Button from 'carbon-react/lib/components/button';
import Dialog from 'carbon-react/lib/components/dialog';
import Form from 'carbon-react/lib/components/form';
import Heading from 'carbon-react/lib/components/heading';
import Typography from 'carbon-react/lib/components/typography';
import type { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  & [data-component='dialog'] {
     & > div:first-child {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 16px;
        & > div:first-child {
            padding-top: 0;
        }
     }
  }
`;

export interface JoinUsModalProps extends ComponentPropsWithoutRef<typeof Dialog> {
  onRemindMeLater: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
  onOk: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
  closeable: boolean;
}

const isAfterDec31 = Date.now() > new Date('2024-12-31').getTime();

export function JoinUsDialogContent({ onRemindMeLater, onOk, closeable, ...dialogProps }: JoinUsModalProps) {
  return (
    <StyledDialog {...dialogProps}>
      <Box display="flex" flexDirection="row" maxHeight="332px" maxWidth="656px">
        <Box>
          <img
            // Avoided multiple buckets since this is a temp component
            style={{ height: '332px' }}
            src="https://qa-sit-assets-public.s3.eu-west-1.amazonaws.com/images/join-us.png"
            alt="Join us in Sage Intacct"
          />
        </Box>
        <Form
          rightSideButtons={
            closeable && (
              <Box display="flex" gap={2}>
                <Button buttonType="secondary" onClick={onRemindMeLater}>
                  <Trans>Remind me later</Trans>
                </Button>
                <Button buttonType="primary" onClick={onOk}>
                  <Trans>Got it</Trans>
                </Button>
              </Box>
            )
          }
        >
          <Box pl={4} pt={4} pb={1}>
            <Heading title={<Trans>Come join us in Sage Intacct!</Trans>} divider={false} />
            <Box pt={2}>
              <Typography>
                {!isAfterDec31 ? (
                  <Trans>
                    Sage Intelligent Time is now available within{' '}
                    <a target="_blank" rel="noreferrer" href="https://www.intacct.com/ia/acct/login.phtml">
                      Intacct
                    </a>
                    , so we're closing the standalone version on December 31, 2024. After that, you will access SIT from Intacct and log in
                    with Intacct credentials.
                  </Trans>
                ) : (
                  <Trans>
                    Sage Intelligent Time is now available within{' '}
                    <a target="_blank" rel="noreferrer" href="https://www.intacct.com/ia/acct/login.phtml">
                      Intacct
                    </a>
                    , so we've closed the standalone version. Access SIT directly from Intacct.
                  </Trans>
                )}
              </Typography>
              <Typography>
                {isAfterDec31 ? (
                  <Trans>Contact your account manager to transition to Intacct.</Trans>
                ) : (
                  <Trans>If you would like to transition to Intacct sooner, contact your account manager.</Trans>
                )}
              </Typography>
            </Box>
          </Box>
        </Form>
      </Box>
    </StyledDialog>
  );
}
