import { Trans } from '@lingui/react/macro';
import { loadingChunkRetry, useCanListMyTimesheets } from '@sit/client-shared';
import { useGetTimesheets } from '@web/api/timesheets/use-get-timesheets';
import { ErrorMessage } from '@web/components/ErrorBoundary/ErrorMessage';
import Box from 'carbon-react/lib/components/box';
import Message from 'carbon-react/lib/components/message';
import Preview from 'carbon-react/lib/components/preview';
import { Suspense, lazy } from 'react';
import styled, { css } from 'styled-components';
import ErrorBoundary from '../../components/ErrorBoundary';
import { TimesheetsPage } from './TimesheetsPage';
import { useIsStaff, useTimesheetModals, useTimesheetsPage } from './hooks';

const TimesheetsTable = lazy(() => loadingChunkRetry(() => import('./components/TimesheetsTable')));

const EmbeddedTableContentStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
`;
const DefaultTableContentStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(85)};
  padding: ${({ theme }) => theme.spacing * 3}px;
  border-radius: ${({ theme }) => theme.spacing / 2}px;
`;

const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]};
  height: 100%;
  min-height: 300px;
  overflow: auto;
  position: relative;
  width: 100%;

  ${({ theme }) => (theme.name === 'SIT-Embeded' ? EmbeddedTableContentStyle : DefaultTableContentStyle)}
`;

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
`;

const CannotListTimesheets = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
      <Message variant="error">
        <Trans>
          To list <strong>My timesheets</strong>, your user ID must be associated with an employee record and have permission. Contact your
          admin to update your record.
        </Trans>
      </Message>
    </Box>
  );
};

const Timesheets = () => {
  const { openBulkDeleteTimesheetsModal } = useTimesheetModals();
  const { disableCreateTimesheet, handleAction } = useTimesheetsPage();
  const { data: timesheets, isLoading, isError, error } = useGetTimesheets();
  const isStaff = useIsStaff();
  const canListMyTimesheets = useCanListMyTimesheets();
  const onBulkDelete = (ids: string[]) => {
    openBulkDeleteTimesheetsModal(ids);
  };
  const canViewTable = isStaff || canListMyTimesheets;

  return (
    <TimesheetsPage>
      {canViewTable ? (
        <TableContent data-testid="timesheets-table">
          {isLoading && <Preview height="600px" loading />}
          {isError && (
            <ErrorWrapper>
              <ErrorMessage error={error} />
            </ErrorWrapper>
          )}
          {!isLoading && !isError && (
            <ErrorBoundary>
              <Suspense fallback={<Preview height="600px" loading />}>
                <TimesheetsTable
                  timesheets={timesheets ?? []}
                  disableCreateTimesheet={disableCreateTimesheet}
                  active={false}
                  isStaff={isStaff}
                  onAction={handleAction}
                  onBulkDelete={onBulkDelete}
                />
              </Suspense>
            </ErrorBoundary>
          )}
        </TableContent>
      ) : (
        <CannotListTimesheets />
      )}
    </TimesheetsPage>
  );
};

export default Timesheets;
