import { Trans, useLingui } from '@lingui/react/macro';
import { useGetUser } from '@web/api/users/use-get-user';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import RCMenu, { Divider, Item } from 'rc-menu';
import { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { useTheme } from '../../../styles/theme/hooks';

const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow:
    0 20px 40px 0 rgba(0, 20, 29, 0.1),
    0 10px 20px 0 rgba(0, 20, 29, 0.2);
  width: 220px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.aboveAll + 1};
`;

const Menu = styled(RCMenu)`
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  border-radius: 0;
  box-shadow: none;
  margin-top: 0;
  padding: 0;
`;

const MenuItem = styled(Item)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing}px ${({ theme }) => theme.spacing * 2}px !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.slateTint(95)};
  }

  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.palette.slateTint(80)};
  }

  &:first-child,
  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const UserInfoMenuItem = styled(MenuItem)`
  background-color: ${({ theme }) => theme.palette.slateTint(95)};
  flex-direction: column;
  font-size: 12px;
  text-align: center;
`;
const Username = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  hyphens: auto;
  white-space: normal;
  word-break: break-all;
`;
const ComanyName = styled.span`
  font-style: normal;
  font-weight: 400;
  hyphens: auto;
  white-space: normal;
  word-break: break-all;
`;

export type ProfileMenuActions = 'login' | 'settings' | 'logout';

interface AuthenticatedProfileMenuProps {
  authenticated: true;
  username: string;
  companyName: string;
  onAction: (action: ProfileMenuActions) => void;
}

interface UnauthenticatedProfileMenuProps {
  authenticated?: false;
  onAction: (action: ProfileMenuActions) => void;
}

type ProfileMenuItemsProps = AuthenticatedProfileMenuProps | UnauthenticatedProfileMenuProps;

const ProfileMenuItems = ({ onAction, ...props }: ProfileMenuItemsProps) => {
  if (!props.authenticated) {
    return (
      <Menu>
        <MenuItem key="login" onClick={() => onAction('login')}>
          <Icon type="logout" />
          <Trans>Log in</Trans>
        </MenuItem>
      </Menu>
    );
  }

  const { username, companyName } = props;

  return (
    <Menu>
      <UserInfoMenuItem disabled key="loggedin">
        <Trans>Logged in as</Trans>
        <Username>{username}</Username>
        <ComanyName>{companyName}</ComanyName>
      </UserInfoMenuItem>
      <Divider />
      <MenuItem key="settings" onClick={() => onAction('settings')} data-pendo-id="settings">
        <Icon type="settings" />
        <Trans>Time preferences</Trans>
      </MenuItem>
      <Divider />
      <MenuItem key="logout" onClick={() => onAction('logout')} data-pendo-id="logout">
        <Icon type="logout" />
        <Trans>Logout</Trans>
      </MenuItem>
    </Menu>
  );
};

interface ProfileMenuProps {
  authenticated: boolean;
  onAction: (action: ProfileMenuActions) => void;
}

const ProfileMenu = ({ authenticated, onAction }: ProfileMenuProps) => {
  const { t } = useLingui();
  const [profileOpen, setProfileOpen] = useState(false);
  const { data: user } = useGetUser({
    enabled: authenticated,
  });
  const theme = useTheme();

  const username = user?.username ?? null;
  const companyName = user?.company?.intacctCompanyId;

  const isAuthenticated = authenticated && username && companyName;

  return (
    <Popover
      align={'end'}
      content={
        <Root>
          {isAuthenticated ? (
            <ProfileMenuItems authenticated username={username} companyName={companyName} onAction={onAction} />
          ) : (
            <ProfileMenuItems onAction={onAction} />
          )}
        </Root>
      }
      isOpen={profileOpen}
      onClickOutside={() => setProfileOpen(false)}
      padding={0}
      positions={['bottom']}
      containerStyle={{ zIndex: theme.zIndex.aboveAll.toString() }}
    >
      <IconButton
        data-pendo-id="profile-menu"
        aria-label={isAuthenticated ? t`Profile menu` : t`Log in menu`}
        onClick={() => setProfileOpen((e) => !e)}
      >
        <Icon type="person" color="white" />
      </IconButton>
    </Popover>
  );
};

export default ProfileMenu;
