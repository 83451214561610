import { InfiniteData } from '@tanstack/react-query';
import { useMemo, useRef } from 'react';
import { prepareOptions } from '../helpers';
import { useMapOptions } from './useMapOptions';

export function useDimensionSelector<
  T extends {
    name: string | null;
    externalId?: string | null;
    id: string;
  },
>(data: InfiniteData<T[]> | undefined, value: string | null | undefined, isOpen: boolean) {
  const handleMapOptions = useMapOptions<T>();
  const previouslyFoundIndex = useRef<{ index: number; page: number } | null>(null);

  const notOpenedData = useMemo(() => {
    if (!data?.pages) return null;
    // First, let's see if we can use the previously found index
    // to speed up the search. This seems to only happen when we
    // have the following scenario:
    // 1. Customer A is selected, Project A is selected
    // 2. Customer B is selected, but Project A is still selected
    // In this case, we can use the previously found index to speed up the search
    // to find Project A.
    if (previouslyFoundIndex.current) {
      const { index, page } = previouslyFoundIndex.current;
      const pageData = data.pages[page] ?? [];
      const optionsData = pageData[index] as T | undefined;
      if (optionsData && optionsData.id === value) {
        return optionsData;
      }
    }
    // Darn, we have to search the whole data
    for (let i = 0; i < data.pages.length; i++) {
      const page = data.pages[i] ?? [];
      const optionsDataIndex = page.findIndex((d) => d.id === value);
      if (optionsDataIndex >= 0) {
        const optionsData = page[optionsDataIndex];
        previouslyFoundIndex.current = { index: optionsDataIndex, page: i };
        return optionsData;
      } else {
        previouslyFoundIndex.current = null;
      }
    }
  }, [data?.pages, value]);

  const { options, byValue } = useMemo(() => {
    if (!isOpen) {
      if (notOpenedData) {
        return prepareOptions([notOpenedData], handleMapOptions);
      }
      return { options: [], byValue: new Map<string, T>() };
    }
    const optionsData = data?.pages.flat(1) ?? [];
    return prepareOptions(optionsData, handleMapOptions);
  }, [data?.pages, handleMapOptions, isOpen, notOpenedData]);

  const selectData = value ? byValue.get(value) : undefined;

  const { text, value: innerValue } = selectData ? handleMapOptions(selectData) : { text: '', value: '' };
  return {
    byValue,
    options,
    text,
    innerValue,
  };
}
