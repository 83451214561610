import { InfiniteData, InfiniteQueryObserverBaseResult } from '@tanstack/react-query';
import { CustomSelectChangeEvent } from 'carbon-react/lib/components/select';
import { useState } from 'react';
import { useDimensionSelector } from './useDimensionSelector';

type FetchNextPageFn<TData = unknown, TError = unknown> = InfiniteQueryObserverBaseResult<TData, TError>['fetchNextPage'];

export interface SelectControlOptions<T> {
  fetchNextPage?: FetchNextPageFn<T[]>;
  onChange: (selected: T | undefined) => void;
}

export function useSelectControls<
  T extends {
    name: string | null;
    externalId?: string | null;
    id: string;
  },
>(data: InfiniteData<T[]> | undefined, value: string | null | undefined, { fetchNextPage, onChange }: SelectControlOptions<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const { byValue, innerValue, options, text } = useDimensionSelector(data, value, isOpen);

  const handleChange = (event: CustomSelectChangeEvent) => {
    // `event.selectionConfirmed` is a custom property in the `FilterableSelect` component's `onChange` callback
    // to indicate that the user has selected an option.
    // We don't want to call the component callback's `onChange` if the user has not selected an option.
    // Without this check, every time the user types a character, the component callback's `onChange` will be called
    // and it will subsequently assign the value to the first item in the dropdown list.
    // However, we do want to call the `onChange` callback if the user has cleared the input field.
    const hasCleared = event.target.value === '';
    if (event.target.value == null || (!hasCleared && event.selectionConfirmed === false)) return;
    const selected = byValue.get(event.target.value.toString());
    onChange(selected);
  };

  const loadMore = async () => {
    if (isOpen) {
      await fetchNextPage?.();
    }
  };

  const handleOpen = async () => {
    setIsOpen(true);
    await loadMore();
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    byValue,
    options,
    innerValue,
    text,
    handleChange,
    handleOpen,
    handleBlur,
    loadMore,
  };
}
