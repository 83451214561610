import { useLingui } from '@lingui/react/macro';
import { GetApiDimensionsResponse, TimeClock, useGetDimensions } from '@sit/client-shared';
import { formatDimensionValue } from '@sit/core';
import { useShowIntacctId } from '@web/api/show-intacct-id';
import { stringWithFallback } from '@web/helpers/label';

function mapDimensionValues(clock: TimeClock) {
  const dimensions: Record<string, string> = {};

  for (const dimension of clock.dimensionsValues ?? []) {
    dimensions[dimension.dimensionObjectName] = dimension.id;
  }

  return dimensions;
}

function useMakeDimensionValueField(dimensionValueIds: Record<string, string>) {
  const { t } = useLingui();
  const showIntacctId = useShowIntacctId();

  return (dimension: GetApiDimensionsResponse[number]) => {
    const objectName = dimension.integrationMeta?.objectName;
    const targetId = objectName ? dimensionValueIds[objectName] : undefined;

    const dimensionValue = dimension.values.find(({ id }) => id === targetId);

    return {
      objectName: dimension.integrationMeta?.objectName,
      label: dimension.name,
      id: dimension.id,
      emptyLabel: t`Select ${dimension.name}`,
      optionValue: dimensionValue
        ? formatDimensionValue(showIntacctId, dimensionValue.externalId, dimensionValue.name)
        : stringWithFallback(),
      value: dimensionValue?.id,
    };
  };
}

export function useDimensionValueFields(clock: TimeClock, disabledDimensios: string[] = []) {
  const { data } = useGetDimensions({
    companyId: clock.companyId,
    timesheetId: clock.timesheetId ?? undefined,
  });

  const dimensions = (data ?? []).filter(
    (dimension) =>
      dimension.integrationMeta?.objectName && !['LOCATION', 'DEPARTMENT', 'COSTTYPE'].includes(dimension.integrationMeta.objectName),
  );

  const dimensionValueIds = mapDimensionValues(clock);
  const toField = useMakeDimensionValueField(dimensionValueIds);

  return dimensions.map((dimension) => {
    const fieldData = toField(dimension);

    return {
      disabled: !!fieldData.objectName && disabledDimensios.includes(fieldData.objectName),
      ...fieldData,
    };
  });
}
