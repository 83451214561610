import { Trans } from '@lingui/react/macro';
import { useFlag } from '@web/hooks';
import { dismissMaintenanceWarning, useHideMaintenanceWarning, useMaintenanceWarningVersion } from '@web/redux/general-slice';
import { useAppDispatch } from '@web/redux/store';
import Box from 'carbon-react/lib/components/box';
import Toast from 'carbon-react/lib/components/toast';
import Typography from 'carbon-react/lib/components/typography';
import { useEffect, useState } from 'react';

const MaintenanceMessage = () => {
  const dispatch = useAppDispatch();
  const hideMaintenanceWarning = useHideMaintenanceWarning();
  const maintenanceWarningVersion = useMaintenanceWarningVersion();
  const maintenanceMessage = useFlag('MAINTENANCE_MESSAGE_CLIENTS');
  const [isOpen, setIsOpen] = useState(false);

  const shouldDisplayMaintenanceMessage =
    maintenanceMessage?.active === true &&
    !!maintenanceMessage.message &&
    (!hideMaintenanceWarning || maintenanceWarningVersion !== maintenanceMessage.version);

  /**
   * This useEffect is needed to set the state for "isOpen" otherwise the toast is not displayed after refreshing the page
   */
  useEffect(() => {
    setIsOpen(shouldDisplayMaintenanceMessage);
  }, [shouldDisplayMaintenanceMessage]);

  return (
    <Toast
      variant="warning"
      open={isOpen}
      onDismiss={() => dispatch(dismissMaintenanceWarning(maintenanceMessage?.version || ''))}
      maxWidth="1500"
    >
      <Box pt={2} pb={2}>
        <Typography variant="h3">
          <Trans>Scheduled system maintenance</Trans>
        </Typography>
        {maintenanceMessage?.message ? <p style={{ fontSize: 15 }}>{maintenanceMessage.message}</p> : null}
      </Box>
    </Toast>
  );
};

export default MaintenanceMessage;
