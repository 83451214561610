import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import NotificationTile from './NotificationTile';
import { NotificationData } from './notification-data';

const Root = styled.div`
  height: 480px;
  width: 350px;
  z-index: ${({ theme }) => theme.zIndex.aboveAll};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;

interface NotificationPanelProps extends HTMLAttributes<HTMLDivElement> {
  notifications: NotificationData[];
}

const NotificationsPanel = ({ notifications }: NotificationPanelProps): JSX.Element => {
  return (
    <Root>
      {notifications.map((notification) => (
        <NotificationTile notification={notification} key={notification.id} />
      ))}
    </Root>
  );
};

export default NotificationsPanel;
