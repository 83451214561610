import { useLingui } from '@lingui/react/macro';
import { useGetCurrentCompany } from '@web/api/company/use-get-company';
import { ClockDimensionsField } from '../constants';

export const useFieldLabel = (field: ClockDimensionsField): string => {
  const { data: company } = useGetCurrentCompany();
  const { t } = useLingui();

  const labels = {
    billable: t`Billable`,
    client: company?.clientDisplayName ?? t`Customer`,
    costType: t`Cost type`,
    department: t`Department`,
    description: t`Description`,
    item: t`Item`,
    location: t`Location`,
    notes: t`Notes`,
    project: company?.projectDisplayName ?? t`Project`,
    task: company?.taskDisplayName ?? t`Task`,
    timeType: t`Time type`,
  } as Record<ClockDimensionsField, string>;

  return labels[field];
};
