import { useLingui } from '@lingui/react/macro';
import { loadingChunkRetry } from '@sit/client-shared';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '../../components/ErrorBoundary';
import DashboardContainer from '../DashboardContainer';
import PreviewComponent from './PreviewComponent';

const Privacy = lazy(() => loadingChunkRetry(() => import('./Privacy')));

const PrivacyContainer = () => {
  const { t } = useLingui();
  return (
    <DashboardContainer showSecondaryNav>
      <Helmet>
        <title>{t`Privacy | Sage Intelligent Time`}</title>
      </Helmet>
      <ErrorBoundary>
        <Suspense fallback={<PreviewComponent />}>
          <Privacy />
        </Suspense>
      </ErrorBoundary>
    </DashboardContainer>
  );
};

export default PrivacyContainer;
