import { Trans } from '@lingui/react/macro';
import Button from '@web/components/Shared/Button';
import Help from 'carbon-react/lib/components/help';

interface CreateTimesheetButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export function CreateTimesheetButton({ onClick, disabled }: CreateTimesheetButtonProps) {
  return (
    <>
      <Button
        buttonType={{
          default: 'primary',
          embedded: 'secondary',
        }}
        onClick={onClick}
        data-cy="timesheets-create-new-timesheet"
        data-pendo-id="create-timesheet"
        data-testid="create-timesheet"
        disabled={disabled}
      >
        <Trans>Create a new timesheet</Trans>
      </Button>
      {disabled && (
        <Help>
          <Trans>Companies managed at the entity level cannot create timesheets at the top level.</Trans>
        </Help>
      )}
    </>
  );
}
