import { Trans } from '@lingui/react/macro';
import { GetApiAccountsResponse } from '@sit/client-shared';
import { FlatTableBody, FlatTableCell, FlatTableRow } from 'carbon-react/lib/components/flat-table';
import Typography from 'carbon-react/lib/components/typography';
import { useMemo } from 'react';
import styled from 'styled-components';
import Calendar from './Calendar';

const AssociatedAccounts = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

interface CalendarsProps {
  calendars: GetApiAccountsResponse[number]['calendars'];
}

const Calendars = ({ calendars }: CalendarsProps) => {
  const sorted = useMemo(() => (calendars ? [...calendars].sort((a, b) => a.name.localeCompare(b.name)) : []), [calendars]);
  return (
    <>
      <FlatTableRow>
        <FlatTableCell colspan={6}>
          <FlatTableBody>
            <AssociatedAccounts mb={0}>
              <Trans>Associated accounts</Trans>
            </AssociatedAccounts>
          </FlatTableBody>
        </FlatTableCell>
      </FlatTableRow>
      {sorted.map((calendar) => (
        <Calendar calendar={calendar} key={calendar.id} />
      ))}
    </>
  );
};

export default Calendars;
